import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonProgressBar,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { ErrorBoundary, uniqueID } from "@rpforms/shared/build";
import { useLiveQuery } from "dexie-react-hooks";
import { checkmarkCircleOutline, paperPlane } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { submitDraftForm } from "../actions/forms";
import { createTask } from "../actions/tasks";
import { CalendarEventEntityListItem } from "../components/CalendarEventEntityListItem";
import { TaskListItem } from "../components/CalendarEventTaskListItem";
import { CenteredLoader } from "../components/CenteredLoader";
import { TaskStatus } from "../components/TaskItem";
import db from "../db";
import eventTime from "../hooks/eventTime";

const PaddedArea = styled.div`
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 80px;
`;

const ButtonArea = styled.div`
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    flex-direction: column;
    margin-top: 3%;
`;

const CalendarEventDetails = ({ match }: any) => {
    const eventId = match.params.event_id;
    const entities = useLiveQuery(() => db.entities.toArray());
    const formStore = useLiveQuery(() => db.forms.toArray());
    const taskStore = useLiveQuery(() => db.tasks.toArray());
    const groupStore = useLiveQuery(() => db.formGroups.toArray());
    const [formEntity, setFormEntity] = useState(null);

    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
    const [confirmedSubmit, setConfirmedSubmit] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [showPopover, setShowPopover] = useState(false);

    const [types, setTypes] = useState<any>([]);
    const tasks = useLiveQuery(
        () => db.tasks.where("calendar_event_id").equals(parseInt(eventId, 10)).toArray(),
        [showPopover]
    );
    const dispatch = useDispatch();

    useEffect(() => {
        const initialFetch = async () => {
            setLoading(true);
            setEvent(await db.calendarEvents.get(parseInt(eventId, 10)));
            setTypes(await db.calendarEventTypes.toArray());
            setLoading(false);
        };
        initialFetch();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setRedirect(typeof event === "undefined");
        }, 800);

        return () => {
            clearInterval(timer);
        };
    }, [event]);

    const submitEvent = async () => {
        await syncAll();
        const filteredTasks = event.device_tasks.filter(
            (task) => task.status === 3 || task.status === 4
        );
        await db.calendarEvents.update(event.id, {
            was_completed: true,
            device_tasks: filteredTasks,
        });
        await db.tasks
            .where("status")
            .noneOf([3, 4])
            .and((task) => task.calendar_event_id === event.id)
            .delete();
        const jobData = {
            was_submitted: true,
        };

        const job = {
            id: uniqueID(),
            title: "Eventübertragung " + event.name,
            event_id: event.id,
            description: "Event übertragen",
            state: "idle",
            type: "uploadEvent",
            data: jobData,
        };

        await db.sync.put(job);
        setRedirect(true);
    };

    useEffect(() => {
        if (confirmedSubmit) {
            submitEvent();
        }
    }, [confirmedSubmit]);

    const syncAll = async () => {
        setLoading(true);
        for await (const task of tasks) {
            if (
                task.saveState &&
                task.status !== TaskStatus.IN_QUEUE &&
                task.status !== TaskStatus.SUBMITTED
            ) {
                await dispatch(
                    submitDraftForm({
                        task,
                    })
                );
            }
        }
        setLoading(false);
    };

    const handleSubmitClick = async () => {
        let i = 0;
        for (; i < tasks.length; i++) {
            if (tasks[i].status !== 3 && tasks[i].status !== 4) {
                setShowConfirmSubmit(true);
                break;
            }
        }
        if (i === tasks.length) {
            submitEvent();
        }
    };

    const addForm = async (form) => {
        await createTask({
            calendar_event_id: parseInt(eventId, 10),
            entity: entities.find((e) => e.id === formEntity),
            form,
        });
        setShowPopover(false);
    };

    if (redirect) {
        return <Redirect to={"/calendarevents"} />;
    }

    if (!event || !entities || typeof tasks === "undefined" || !(types.length > 0)) {
        return <CenteredLoader />;
    }

    const isLoading = !formStore || !groupStore || !taskStore;

    const entityIds = tasks.map((t) => t.entity_id);
    const relevantEntities = entities.filter((e) => entityIds.includes(e.id));
    const eventType =
        types.find((t) => t.id === event.calendar_event_type_id) ??
        types.find((t) => t.name === "Ausführung");
    const isAusführung = eventType.name === "Ausführung";
    const cancelButtonProps = {
        text: "Abbrechen",
        role: "cancel",
    };
    const entity = entities.find((e) => e.id === event.entity_id);
    const submitButtonProps = {
        text: "Absenden",
        handler: () => setConfirmedSubmit(true),
    };
    return (
        <>
            <>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{event.name}</IonTitle>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref={"/calendarevents"} />
                        </IonButtons>
                    </IonToolbar>
                    {loading && <IonProgressBar color="secondary" type="indeterminate" />}
                </IonHeader>
                <IonContent class="ion-padding">
                    <ErrorBoundary>
                        {!isLoading && (
                            <IonModal
                                cssClass={"form-add-popover"}
                                isOpen={showPopover}
                                onDidDismiss={(e) => setShowPopover(false)}
                            >
                                <IonContent>
                                    {groupStore
                                        .sort((groupA, groupB) => {
                                            if (groupA.name < groupB.name) {
                                                return -1;
                                            }
                                            if (groupA.name > groupB.name) {
                                                return 1;
                                            }
                                            return 0;
                                        })
                                        .map((group) => {
                                            return (
                                                <IonList key={group.id}>
                                                    <IonListHeader>
                                                        <IonLabel>{group.name}</IonLabel>
                                                    </IonListHeader>
                                                    {formStore
                                                        .filter(
                                                            (f) =>
                                                                f.form_group &&
                                                                f.form_group.id === group.id
                                                        )
                                                        .sort((formA, formB) => {
                                                            if (formA.name < formB.name) {
                                                                return -1;
                                                            }
                                                            if (formA.name > formB.name) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        })
                                                        .map((form) => {
                                                            return (
                                                                <IonItem
                                                                    key={form.id}
                                                                    onClick={() => addForm(form)}
                                                                >
                                                                    <IonLabel
                                                                        style={{
                                                                            marginLeft: "30px",
                                                                        }}
                                                                    >
                                                                        {form.name}
                                                                    </IonLabel>
                                                                </IonItem>
                                                            );
                                                        })}
                                                </IonList>
                                            );
                                        })}
                                    <IonList>
                                        <IonListHeader>
                                            <IonLabel>{"Ungruppiert"}</IonLabel>
                                        </IonListHeader>
                                        {formStore
                                            .filter((f) => !f.form_group)
                                            .map((form) => {
                                                return (
                                                    <IonItem
                                                        key={form.id}
                                                        onClick={() => addForm(form)}
                                                    >
                                                        <IonLabel style={{ marginLeft: "30px" }}>
                                                            {form.name}
                                                        </IonLabel>
                                                    </IonItem>
                                                );
                                            })}
                                    </IonList>
                                </IonContent>
                            </IonModal>
                        )}
                        <PaddedArea>
                            <IonAlert
                                isOpen={showConfirmSubmit}
                                onDidDismiss={() => setShowConfirmSubmit(false)}
                                header="Wirklich abschicken?"
                                message={
                                    "Es sind noch nicht alle Aufgaben abgeschlossen. Wenn Sie das Event trotzdem abschließen, gehen alle offenen Formulare verloren. Trotzdem abschließen?"
                                }
                                buttons={[cancelButtonProps, submitButtonProps]}
                            />
                            <IonText>
                                <h4 style={{ textAlign: "center" }}>{event.name}</h4>
                            </IonText>
                            <IonText>
                                <h4>{isAusführung ? "Notizen zum Material" : "Beschreibung"}</h4>
                                <p>{event.notes || "Keine Notizen vorhanden."}</p>
                            </IonText>
                            <IonText>
                                <h4>Zeitraum</h4>
                                <p>{eventTime(event, isAusführung)}</p>
                            </IonText>
                            {event.reptext && (
                                <IonText>
                                    <h4>Reparatur Informationen</h4>
                                    {event.reptext.split("\n").map((l) => {
                                        return (
                                            <p style={{ margin: 0, padding: 0 }} key={l}>
                                                {l}
                                            </p>
                                        );
                                    })}
                                    <br />
                                </IonText>
                            )}
                            {event.location && (
                                <IonText>
                                    <h4>Ort</h4>
                                    <p>{event.location}</p>
                                </IonText>
                            )}
                            {entity && (
                                <IonText>
                                    <h4>Objekt</h4>
                                    <p>{entity.name}</p>
                                    {entity.address?.postalcode && (
                                        <div>
                                            {entity.address.postalcode}{" "}
                                            {entity.address?.city && (
                                                <span>{entity.address.city}</span>
                                            )}
                                        </div>
                                    )}
                                    {entity.address?.street && <div>{entity.address.street}</div>}
                                    {entity.address?.country && <div>{entity.address.country}</div>}
                                    <br />
                                </IonText>
                            )}
                            {event.phone && (
                                <IonText>
                                    <h4>Telefonnummer</h4>
                                    <p>{event.phone}</p>
                                </IonText>
                            )}{" "}
                            {event.contactperson && (
                                <IonText>
                                    <h4>Ansprechpartner</h4>
                                    <p>{event.contactperson}</p>
                                </IonText>
                            )}
                            {tasks.length > 0 && (
                                <>
                                    <IonText>
                                        <h4>Aufgaben</h4>
                                    </IonText>
                                    <IonList>
                                        {relevantEntities.map((e) => {
                                            return (
                                                <div key={e.id}>
                                                    <CalendarEventEntityListItem
                                                        showFormSelector={() => {
                                                            setShowPopover(true);
                                                            setFormEntity(e.id);
                                                        }}
                                                        entity={e}
                                                    />
                                                    {tasks
                                                        .filter((task) => task.entity_id === e.id)
                                                        .sort((a, b) => {
                                                            const nameA = a.name; // .toUpperCase();
                                                            const nameB = b.name; // .toUpperCase();
                                                            if (nameA < nameB) {
                                                                return -1;
                                                            }
                                                            if (nameA > nameB) {
                                                                return 1;
                                                            }

                                                            // names must be equal
                                                            return 0;
                                                        })
                                                        .map((task) => {
                                                            return (
                                                                <TaskListItem
                                                                    event={event}
                                                                    key={task.id}
                                                                    task={task}
                                                                    entity={e}
                                                                />
                                                            );
                                                        })}
                                                </div>
                                            );
                                        })}
                                    </IonList>
                                </>
                            )}
                            {tasks.length > 0 && (
                                <ButtonArea>
                                    <IonText>
                                        <strong>1. Schritt</strong>: Klicken Sie hier, um alle
                                        gespeicherten Aufgaben abzuschließen.
                                    </IonText>
                                    <IonButton color={"success"} onClick={syncAll}>
                                        <IonIcon icon={checkmarkCircleOutline} />{" "}
                                        <span className="mr-1"></span>Alle gespeicherten Aufgaben
                                        abschließen
                                    </IonButton>
                                </ButtonArea>
                            )}
                            <ButtonArea>
                                {tasks.length > 0 && (
                                    <IonText>
                                        <strong>2. Schritt</strong>: Klicken Sie hier, sobald alle
                                        Aufgaben abgeschlossen sind, um das Event abzuschließen und
                                        die Aufgaben somit bereit zum Versenden zu machen.
                                    </IonText>
                                )}
                                <IonButton color={"success"} onClick={handleSubmitClick}>
                                    <IonIcon icon={paperPlane} /> <span className="mr-1"></span>
                                    Event abschließen
                                </IonButton>
                            </ButtonArea>
                        </PaddedArea>
                    </ErrorBoundary>
                </IonContent>
            </>
        </>
    );
};

export default CalendarEventDetails;
