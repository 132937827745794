// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import React from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import { UploadForm } from "../components/uploadField/UploadForm";
import { BaseField } from "./BaseField";
import { Description, Hint, Required, Width } from "./configurables";
import { AutoFill } from "./configurables/AutoFill";
import { Conditional } from "./configurables/Conditional";
import { HashCode } from "./configurables/HashCode";

const getImageStyles = (field) => ({
    width: field.imageWidth,
    height: field.imageHeight,
    maxWidth: "100%",
});

const CENTERED_STRING = "d-flex justify-content-center";

export class ImageField extends BaseField {
    public icon = "picture-o";
    public fieldType = "Bild";
    public attributeNames = ["source", "imageWidth", "imageHeight", "centered"];

    public getConfigurables() {
        return [HashCode, AutoFill, Conditional, Required, Description, Width, Hint];
    }

    public EditComponent({ layout, designer, schema, register, field }) {
        const onUpload = ({ image }) => {
            console.log(image);
            field.source = image.source;
            (document.querySelector(`input[name='${field.inputName("source")}']`) as any).value =
                image.source;
        };

        const preview = field.source ? (
            <div className="mt-3">
                <strong>Aktuelles Bild</strong> <br />
                <div className={field.centered ? CENTERED_STRING : ""}>
                    <img src={field.source} style={getImageStyles(field)} />
                </div>
            </div>
        ) : (
            ""
        );
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                {field.configureInput(
                    <>
                        <UploadForm
                            onUpload={onUpload}
                            namePattern={"image"}
                            uploads={[]}
                            subfields={[]}
                            variables={[]}
                            hashCode={field.hashCode}
                        />

                        <label className="mt-3">Bildquelle</label>
                        <div className="text-muted">
                            Geben Sie hier den Direktlink zum Bild ein oder laden Sie ein Bild mit
                            dem Uploader oben hoch.
                        </div>

                        <input
                            type={"string"}
                            name={field.inputName("source")}
                            className="form-control"
                            defaultValue={field.source}
                            ref={register}
                        />

                        <hr />

                        <div className="row">
                            <div className="col">
                                <label className="mt-3">Breite</label>
                                <div className="text-muted">
                                    Definieren Sie hier die Breite des Bildes. (Erlaubt: Pixelwerte,
                                    Prozentwerte, "auto")
                                </div>

                                <input
                                    type={"string"}
                                    name={field.inputName("imageWidth")}
                                    className="form-control"
                                    defaultValue={field.imageWidth || "auto"}
                                    ref={register}
                                />
                            </div>
                            <div className="col">
                                <label className="mt-3">Höhe</label>
                                <div className="text-muted">
                                    Definieren Sie hier die Höhe des Bildes. (Erlaubt: Pixelwerte,
                                    Prozentwerte, "auto")
                                </div>

                                <input
                                    type={"string"}
                                    name={field.inputName("imageHeight")}
                                    className="form-control"
                                    defaultValue={field.imageHeight || "auto"}
                                    ref={register}
                                />
                            </div>
                        </div>

                        <div className="mt-3">
                            <label>Bild zentrieren</label>
                            <div className="text-muted">Zentriert das Bild horizontal.</div>
                            <input
                                type={"checkbox"}
                                name={field.inputName("centered")}
                                ref={register}
                                defaultChecked={field.centered}
                            />
                        </div>

                        <hr />
                        {preview}
                    </>
                )}
            </FieldWrapper>
        );
    }

    public InputComponent({ layout, designer, schema, register, field }) {
        return field.PresenterComponent({ field });
    }

    public PresenterComponent({ field }: { field: any }): any {
        return (
            <div className={field.centered ? CENTERED_STRING : ""}>
                <img src={field.source} style={getImageStyles(field)} />
            </div>
        );
    }
}
