import config, { axiosClient } from "../config";
import db from "../db";

export const fetchCalendarEventTypesRemotely = () => async (dispatch) => {
    const response = await axiosClient.get(`${config.ENDPOINT_URL}/calendar_event_types.json`, {
        headers: { authorization: config.DEVICE_TOKEN },
    });

    const types = [...response.data];
    await db.calendarEventTypes.bulkPut(types);
};
