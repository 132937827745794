import { Network } from "@capacitor/core";
import { uniqueID } from "@rpforms/shared/build/utils";
import config from "./config";

export const uniqueNumber = () => {
    // TODO: safer method of defining IDB primaryKeys?
    return uniqueID("");
};

export const hasStableConnection = async () => {
    // fetch (and update) remotely
    const { connected, connectionType }: any = await Network.getStatus();
    if (config.CHECK_NETWORK_SPEED === "0") {
        return true;
    }

    if (
        !connected ||
        (connectionType !== "wifi" && connectionType !== "3g" && connectionType !== "4g")
    ) {
        return false;
    }
    return true;
};
