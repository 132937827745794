import { uniq } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TaskStatus } from "../components/TaskItem";
import db from "../db";

const filterAndSort = (array) => {
    return array.filter((e) => !!e).sort((a, b) => a.customer.name.localeCompare(b.customer.name));
};

export const useGroupedEntities = (entities, deps = []) => {
    const [entitiesWithTasks, setEntitiesWithTasks] = useState([]);
    useEffect(() => {
        const groupEntities = async () => {
            const group = [];
            for await (const entity of entities) {
                const taskCount = await db.tasks
                    .where("entity_id")
                    .equals(entity.id)
                    .and((task) => task.status !== TaskStatus.SUBMITTED)
                    .count();

                if (taskCount > 0) {
                    group.push(entity);
                }
            }

            setEntitiesWithTasks(group);
        };

        groupEntities();
    }, [entities]);

    return [filterAndSort(entities), filterAndSort(entitiesWithTasks)];
};
