export const SIGNIN_FAILURE = "SIGNIN_FAILURE";
export const AUTH_USER = "AUTH_USER";
export const UNAUTH_USER = "UNAUTH_USER";
export const CURRENT_USER = "CURRENT_USER";
export const GET_DEVICES = "GET_DEVICES";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const ADD_FORM = "ADD_FORM";
export const COMPLETE_TASK = "COMPLETE_TASK";
export const FORM_CREATE = "FORM_CREATE";
export const NEW_DEVICE_REGISTRATION = "NEW_DEVICE_REGISTRATION";
export const UPDATE_DEVICE = "UPDATE_DEVICE";
export const ADD_DEVICE = "ADD_DEVICE";
export const GET_FORMS = "GET_FORMS";
export const UPDATE_FORM = "UPDATE_FORM";
export const FORM_SUBMISSION_CREATED = "FORM_SUBMISSION_CREATED";
export const LOADING_ENTITIES = "LOADING_ENTITIES";
export const STOP_LOADING_ENTITIES = "STOP_LOADING_ENTITIES";
export const SET_ENTITIES = "SET_ENTITIES";
export const FORM_ENTRIES_LOADED = "FORM_ENTRIES_LOADED";
export const FORM_ENTRIES_DEVICE_LOADED = "FORM_ENTRIES_DEVICE_LOADED";
export const DELETE_FORM = "DELETE_FORM";
export const SET_FORMS = "SET_FORMS";
export const DELETE_DEVICE = "DELETE_DEVICE";
export const ADD_ENTITY = "ADD_ENTITY";
export const STOP_LOADING_SUBMISSIONS = "STOP_LOADING_SUBMISSIONS";
export const LOADING_SUBMISSIONS = "LOADING_SUBMISSIONS";
export const SET_SUBMISSIONS = "SET_SUBMISSIONS";
export const SET_SYNC_JOBS = "SET_SYNC_JOBS";
export const WORKING_SYNC_JOBS = "WORKING_SYNC_JOBS";
export const ADD_SUBMISSION = "ADD_SUBMISSION";
export const STOP_WORKING_SYNC_JOBS = "STOP_WORKING_SYNC_JOBS";
export const DELETE_SYNC_JOB = "STOP_LOADING_SUBMISSIONS";
export const ADD_SYNC_JOB = "ADD_SYNC_JOB";
export const UPDATE_SYNC_JOB = "UPDATE_SYNC_JOB";
export const RESET_SYNC_JOB = "RESET_SYNC_JOB";
export const COMPLETE_SYNC_JOB = "COMPLETE_SYNC_JOB";
export const FAIL_SYNC_JOB = "FAIL_SYNC_JOB";
export const SET_TASKS = "SET_TASKS";
export const UPDATE_TASK = "UPDATE_TASK";
export const ADD_TASK = "ADD_TASK";
export const LOADING_TASKS = "LOADING_TASKS";
export const STOP_LOADING_TASKS = "STOP_LOADING_TASKS";
export const GET_ENTITIES = "GET_ENTITIES";
export const DELETE_ENTITY = "DELETE_ENTITY";
export const CREATE_ENTITY = "CREATE_ENTITY";
export const UPDATE_ENTITY = "UPDATE_ENTITY";
export const FORM_ENTRIES_LIST_LOADED = "FORM_ENTRIES_LIST_LOADED";
export const FORM_ENTRIES_FORM_LOADED = "FORM_ENTRIES_FORM_LOADED";
export const FORM_ENTRIES_SINGLE_LOADED = "FORM_ENTRIES_SINGLE_LOADED";
export const NEW_DEVICE_TASK = "NEW_DEVICE_TASK";
export const ADD_WIDGET = "ADD_WIDGET";
export const LOAD_WIDGETS = "LOAD_WIDGETS";
export const REMOVE_ENTITY = "REMOVE_ENTITY";
export const REMOVE_TASK = "REMOVE_TASK";
export const OFFLINE_START_LOADING = "OFFLINE_START_LOADING";
export const OFFLINE_STOP_LOADING = "OFFLINE_STOP_LOADING";
export const MERGE_REMOTE_TASKS = "MERGE_REMOTE_TASKS";
export const UNSET_ERROR = "UNSET_ERROR";
export const SET_ERROR = "SET_ERROR";
export const SET_FORM_ENTRIES = "SET_FORM_ENTRIES";
export const LOADING_FORM_ENTRIES = "LOADING_FORM_ENTRIES";
export const STOP_LOADING_FORM_ENTRIES = "STOP_LOADING_FORM_ENTRIES";
export const ADD_FORM_ENTRY = "ADD_FORM_ENTRY";
export const SET_CALENDAR_EVENTS = "SET_CALENDAR_EVENTS";
export const GET_CALENDAR_EVENT_TYPES = "GET_CALENDAR_EVENT_TYPES";
