import { Capacitor } from "@capacitor/core";
import { IonButton, IonContent, IonIcon } from "@ionic/react";
import {
    BootstrapLayout,
    ErrorBoundary,
    FormPresenter,
    getFormData,
    validateRequiredFields,
} from "@rpforms/shared/build";
import { SchemaBuilder } from "@rpforms/shared/build/spec/SchemaBuilder";
import { useLiveQuery } from "dexie-react-hooks";
import { arrowBack, paperPlane, save } from "ionicons/icons";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { submitForm } from "../actions/forms";
import { updateTask } from "../actions/tasks";
import { CenteredLoader } from "../components/CenteredLoader";
import LeistungsnachweisAlert from "../components/LeistungsnachweisAlert";
import { SubmissionNameModal } from "../components/SubmissionNameModal";
import { TaskStatus } from "../components/TaskItem";
import db from "../db";
import { useHiddenTabs } from "../hooks/useHiddenTabs";
// tslint:disable-next-line:no-var-requires
const logoPath = require("../images/logo.svg");

const TaskDetails = React.memo(({ history, match }: any) => {
    useHiddenTabs();

    const { entity_id, task_id: unknownTaskId } = match.params;
    let taskId = unknownTaskId;

    if (Number.isInteger(Number(unknownTaskId))) {
        taskId = parseInt(unknownTaskId, 10);
    }

    const dispatch = useDispatch();
    const [canSubmit, setCanSubmit] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [nameDialog, setNameDialog] = useState(false);
    const [showLeistungsnachweisAlert, setShowLeistungsnachweisAlert] = useState(false);
    const [name, setName] = useState("");

    const entity = useLiveQuery(() => db.entities.get(parseInt(entity_id, 10)));
    const task = useLiveQuery(() => db.tasks.get(taskId));
    const form = useLiveQuery(() => (task ? db.forms.get(task.form_id) : null), [task]);
    const formEntry = useLiveQuery(
        () => (task && task.form_entry_id ? db.formEntries.get(task.form_entry_id) : null),
        [task]
    );

    const formJson = form ? form.latest_revision.form_data : task ? task.form_data : null;

    useEffect(() => {
        const ionTabs = document.querySelector("ion-tab-bar");

        const c = setInterval(() => {
            ionTabs.classList.add("hidden");
        }, 100);
        return () => {
            clearInterval(c);
            ionTabs.classList.remove("hidden");
        };
    }, [task, match.params.task_id]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (typeof task === "undefined") {
                return history.push(`/`);
            }
        }, 800);

        return () => {
            clearInterval(timer);
        };
    }, [task]);

    const schemaBuilder = useMemo(() => {
        if (!formJson) {
            return;
        }

        const b = new SchemaBuilder();
        b.use(formJson);
        return b;
    }, [formJson]);

    // TODO: test if this is still necessary
    useEffect(() => {
        const conditionalTimer = setInterval(() => {
            const d = Array.from(document.querySelectorAll("input"));
            d.map((input) => input.dispatchEvent(new Event("change")));
        }, 100);
        return () => {
            clearInterval(conditionalTimer);
        };
    }, [task, match.params.task_id]);

    const submitEntry = async (newName) => {
        if (!newName) {
            alert("Bitte einen Namen für dieses Protokoll auswählen");
            return;
        }

        setCanSubmit(false);
        setNameDialog(false);
        setSubmitted(true);
        dispatch(updateTask({ ...task, name: newName }));
        await dispatch(
            submitForm({
                name: newName,
                schemaBuilder,
                params: match.params,
                task: { ...task, name: newName },
                form,
                formData: getFormData(),
            })
        );

        setCanSubmit(true);
    };

    const handleEmptyRequiredFields = (requiredFields) => {
        let message = "Bitte füllen Sie die Pflichtfelder (rot markiert) aus";
        const imageUploadField = document.querySelector("div[requiredImages]");
        if (imageUploadField) {
            const requiredImages = Number(imageUploadField.getAttribute("requiredImages"));
            if (requiredImages > 0) {
                if (validateUploadedImageCount(requiredImages, imageUploadField) === false) {
                    message += `, laden Sie mindestens ${requiredImages} Bilder hoch`;
                }
            }
        }
        message += " oder fügen Sie eine Unterschrift hinzu";
        requiredFields.forEach((emptyField) => {
            emptyField.classList.add("myEmpty");
        });
        alert(message);
        requiredFields[0].scrollIntoView();
        return false;
    };

    const validate = () => {
        const isHidden = (el: Element) => {
            const isHiddenItself = getComputedStyle(el).display === "none";
            if (isHiddenItself) {
                return true;
            }

            let parent = el.parentElement;
            while (parent) {
                const hasHiddenParent = getComputedStyle(parent).display === "none";
                if (hasHiddenParent) {
                    return true;
                }
                parent = parent.parentElement;
            }

            return false;
        };

        const requiredFields = Array.from(document.querySelectorAll("[required]")).filter(
            (el) => !isHidden(el)
        );
        if (!validateRequiredFields(requiredFields, handleEmptyRequiredFields)) {
            return false;
        }

        return true;
    };

    const saveEntry = async (ev, notify = true) => {
        if (Capacitor.platform !== "web") {
            Capacitor.Plugins.Keyboard.hide().then((r) => r);
        }

        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        if (!validate()) {
            return;
        }
        await dispatch(
            updateTask({
                ...task,
                status: TaskStatus.DRAFT,
                name: schemaBuilder.formEntryName(entity, form, task),
                saveState: getFormData(),
            })
        );

        if (!notify) {
            return;
        }

        const element = Array.from(document.querySelectorAll("input[required]"));
        element.forEach((requiredField) => {
            requiredField.classList.remove("myEmpty");
        });

        alert("Formular gespeichert.");
        return false;
    };

    const openNameDialog = async (ev) => {
        if (ev) {
            ev.stopPropagation();
            ev.preventDefault();
        }
        if (!validate()) {
            return;
        }
        await saveEntry(ev, false);

        if (!name) {
            // get name from config field if possible
            setName(
                schemaBuilder.formEntryName({
                    entity,
                    form,
                    task,
                })
            );
        }
        setNameDialog(true);
        return false;
    };

    const cancel = () => {
        setNameDialog(false);
    };

    if (submitted && entity?.id) {
        return <Redirect to={`/entities/entity/${entity.id}`} />;
    }

    if (submitted && task.entity_id) {
        return <Redirect to={`/entities/entity/${task.entity_id}`} />;
    }

    if (!task || !schemaBuilder || !form || (task.form_entry_id && !formEntry)) {
        return <CenteredLoader />;
    }

    const resetDateIfNeeded = (fd) => {
        const fields = [];
        const flatField = (field: any) => {
            fields.push(field);
            if (field.subfields) {
                field.subfields.forEach(flatField);
            }
        };

        JSON.parse(formJson).fields.forEach(flatField);
        if (window.location.href.includes("?event=")) {
            fields.forEach((field) => {
                if (field.overwriteDate) {
                    fd[field.hashCode] = moment().format("YYYY-MM-DD");
                }
            });
        }
        return fd;
    };

    const resetSignatureIfNeeded = (fd) => {
        const fields = [];
        const flatField = (field: any) => {
            fields.push(field);
            if (field.subfields) {
                field.subfields.forEach(flatField);
            }
        };

        JSON.parse(formJson).fields.forEach(flatField);
        fields.forEach((field) => {
            if (field.overwriteSignature) {
                fd[field.hashCode] = "";
            }
        });
        return fd;
    };

    return (
        <IonContent style={{ "--background": "#666" }}>
            <div className="form-container" data-form-id={form.id}>
                <br />
                <br />
                <FormButtonRow
                    task={task}
                    canSubmit={canSubmit}
                    saveEntry={saveEntry}
                    openNameDialog={openNameDialog}
                    showLeistungsnachweisAlert={showLeistungsnachweisAlert}
                    setShowLeistungsnachweisAlert={setShowLeistungsnachweisAlert}
                />

                <ErrorBoundary>
                    <FormPresenter
                        layout={BootstrapLayout}
                        entity={entity}
                        logoPath={logoPath}
                        form={form}
                        schema={schemaBuilder}
                        saveState={
                            task.saveState
                                ? task.saveState
                                : formEntry
                                ? resetSignatureIfNeeded(resetDateIfNeeded(formEntry.form_data))
                                : {}
                        }
                    />
                </ErrorBoundary>

                <FormButtonRow
                    task={task}
                    canSubmit={canSubmit}
                    saveEntry={saveEntry}
                    openNameDialog={openNameDialog}
                    showLeistungsnachweisAlert={showLeistungsnachweisAlert}
                    setShowLeistungsnachweisAlert={setShowLeistungsnachweisAlert}
                />
                <br />
                <br />
                <br />
            </div>
            {nameDialog && (
                <SubmissionNameModal defaultName={name} submitEntry={submitEntry} cancel={cancel} />
            )}
            {showLeistungsnachweisAlert && <LeistungsnachweisAlert />}
        </IonContent>
    );
});

const FormButtonRow = ({
    task,
    canSubmit,
    saveEntry,
    openNameDialog,
    showLeistungsnachweisAlert,
    setShowLeistungsnachweisAlert,
}) => (
    <div style={{ textAlign: "center" }}>
        <IonButton
            color={"primary"}
            onClick={() => {
                const eventId = new URLSearchParams(window.location.search).get("event");
                if (eventId) {
                    return window.location.replace(`/calendarevents/event/${eventId}`);
                }
                return window.location.replace("/entities/entity/" + task.entity_id);
            }}
        >
            <IonIcon icon={arrowBack} /> Zurück
        </IonButton>
        <IonButton
            className={canSubmit ? "can-submit" : "cant-submit"}
            onClick={(ev) => {
                if (
                    (task.name === "00_Leistungsnachweis" ||
                        task.form_name === "00_Leistungsnachweis") &&
                    !showLeistungsnachweisAlert
                ) {
                    setShowLeistungsnachweisAlert(true);
                    return;
                }
                openNameDialog(ev);
                return false;
            }}
            color={"primary"}
        >
            <IonIcon icon={paperPlane} /> Abschließen
        </IonButton>{" "}
        <IonButton
            onClick={() => {
                if (
                    (task.name === "00_Leistungsnachweis" ||
                        task.form_name === "00_Leistungsnachweis") &&
                    !showLeistungsnachweisAlert
                ) {
                    setShowLeistungsnachweisAlert(true);
                    return;
                }
                saveEntry();
            }}
            color={"warning"}
        >
            <IonIcon icon={save} /> Speichern
        </IonButton>
    </div>
);

const validateUploadedImageCount = (requiredImages, imageUploadField) => {
    const inputField = imageUploadField.querySelector("input");
    const numberOfUploads = JSON.parse(inputField.value).length;
    if (requiredImages > numberOfUploads) {
        return false;
    }
    return true;
};

export default withRouter(TaskDetails);
