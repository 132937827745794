import { IonItem, IonLabel } from "@ionic/react";
import React from "react";
import { Link } from "react-router-dom";
import { EntityTaskCount } from "../components/EntityTaskCount";

export const EntityListItem = (props) => {
    return (
        <Link to={`/entities/entity/${props.entity.id}`}>
            <IonItem>
                <IonLabel>
                    <strong>
                        {props.entity.customer.name} - {props.entity.name}
                    </strong>{" "}
                    (<EntityTaskCount entity={props.entity} />)
                </IonLabel>
            </IonItem>
        </Link>
    );
};
