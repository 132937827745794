import moment from "moment";
import { TaskStatus } from "../components/TaskItem";
import config, { axiosClient } from "../config";
import db from "../db";
import { uniqueNumber } from "../utils";

import { STOP_LOADING_TASKS } from "./types";

const stopLoading = () => {
    return {
        type: STOP_LOADING_TASKS,
        payload: {},
    };
};

export const createTask = (
    { calendar_event_id, entity, form, task = {} }: any,
    isDuplicate = false
) => {
    const newTask = {
        ...task,
        calendar_event_id,
        task_id: task.id,
        id: uniqueNumber(),
        entity_id: entity.id,
        name: task.name || form.name,
        isLocal: true,
        isDuplicate,
        form_id: form.id,
        start: +new Date(),
        end: +new Date(),
        status: task.status !== TaskStatus.IN_QUEUE ? task.status : TaskStatus.DRAFT,
        startFormatted: moment().format("DD.MM.YYYY"),
        endFormatted: moment().format("DD.MM.YYYY"),
    };

    return db.tasks.put({
        ...newTask,
    });
};

export function updateTask(task) {
    return async (dispatch) => {
        await db.tasks.put(task);
    };
}

export const removeTask = (task) => {
    return db.tasks.delete(task.id);
};

export const fetchTasksRemotely = () => async (dispatch) => {
    const response = await axiosClient.get(
        `${config.ENDPOINT_URL}/devices/${config.DEVICE_ID}/device_tasks.json`
    );
    const tasks = [...response.data];

    for await (const task of tasks) {
        if (!task.form_entry_id) {
            continue;
        }

        const taskResponse = await axiosClient.get(
            `${config.ENDPOINT_URL}/form_entries/${task.form_entry_id}.json`
        );
        await db.formEntries.put(taskResponse.data);
    }

    // remove empty tasks not being present in the remote tasks set anymore
    const dbTasks = await db.tasks.filter((e) => e.isLocal === false).toArray();
    for await (const remoteTask of dbTasks) {
        if (!tasks.find((task) => task.id === remoteTask.id) && !remoteTask.saveState) {
            await db.tasks.delete(remoteTask.id);
        }
    }

    const preparedTasks = [];

    for await (const task of tasks) {
        const existingTask = await db.tasks.get(task.id);
        preparedTasks.push({
            ...task,
            entity_id: task.entity.id,
            isLocal: false,
            saveState: existingTask ? existingTask.saveState : null,
        });
    }

    await db.tasks.bulkPut(preparedTasks);

    // remove empty tasks not being present in the remote tasks set anymore
    const remoteTasks = await db.tasks.filter((e) => e.isLocal === false).toArray();
    for await (const remoteTask of remoteTasks) {
        if (!remoteTask.startFormatted || !remoteTask.endFormatted) {
            remoteTask.startFormatted = moment(remoteTask.start).format("DD.MM.YYYY");
            remoteTask.endFormatted = moment(remoteTask.end).format("DD.MM.YYYY");
            remoteTask.start = Date.parse(remoteTask.start);
            remoteTask.end = Date.parse(remoteTask.end);
        }

        if (remoteTask.saveState) {
            continue;
        }

        // Remote Task is not present anymore.
        if (!tasks.find((task) => task.id === remoteTask.id)) {
            await db.tasks.delete(remoteTask.id);
            continue;
        }

        if (remoteTask.end && remoteTask.start && remoteTask.end <= +new Date()) {
            await db.tasks.delete(remoteTask.id);
        }
    }

    dispatch(stopLoading());
};
