import config, { axiosClient } from "../config";
import db from "../db";
import { LOADING_ENTITIES, SET_ENTITIES, STOP_LOADING_ENTITIES } from "./types";

export const setEntities = (payload) => {
    return {
        type: SET_ENTITIES,
        payload,
    };
};

export const fetchEntitiesRemotely = () => async (dispatch) => {
    const response = await axiosClient.get(
        `${config.ENDPOINT_URL}/devices/${config.DEVICE_ID}/entities.json`,
        {
            headers: { authorization: config.DEVICE_TOKEN },
        }
    );

    const entities = await response.data;
    await db.entities.bulkPut(entities);
    dispatch(setEntities(entities));
};
