/* eslint-disable */
import formGroupReducer from "@rpforms/shared/build/reducers/formGroupReducer";
import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import entitiesReducer from "./entitiesReducer";
import errorsReducer from "./errorsReducer";
import formReducer from "./formReducer";
import storageReducer from "./storageReducer";
import syncReducer from "./syncReducer";
import taskReducer from "./taskReducer";

const rootReducer = combineReducers({
    form,
    entities: entitiesReducer,
    forms: formReducer,
    sync: syncReducer,
    tasks: taskReducer,
    formGroups: formGroupReducer,
    errors: errorsReducer,
    storage: storageReducer,
});

export default rootReducer;
