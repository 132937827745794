import moment from "moment";

const longFormat = "DD.MM.YYYY HH:mm";
const shortFormat = "DD.MM.YYYY";

const eventTime = (event, isAusführung) => {
    if (isAusführung) {
        if (event.scheduled_from) {
            return `${moment.utc(event.scheduled_from).format(longFormat)} - ${moment
                .utc(event.scheduled_to)
                .format(longFormat)}`;
        }
        return "Ohne Zeitraum";
    }
    let scheduledFrom: any = moment.utc(event.scheduled_from);
    let scheduledTo: any = moment.utc(event.scheduled_to);

    scheduledFrom = scheduledFrom.format(parseInt(scheduledFrom.hours(), 10) === 0 ? shortFormat : longFormat);
    scheduledTo = scheduledTo.format(parseInt(scheduledTo.hours(), 10) === 0 ? shortFormat : longFormat);

    return scheduledFrom + " - " + scheduledTo;
};

export default eventTime;
