import { useCallback, useRef } from "react";

export const useDebounceCallback = (callback: (...args: any[]) => void, delay: number) => {
    const timeout = useRef<number | undefined>(undefined);

    const debouncedFunction = useCallback(
        (...args: any[]) => {
            if (timeout.current !== undefined) {
                clearTimeout(timeout.current);
            }
            timeout.current = window.setTimeout(() => {
                callback(...args);
            }, delay);
        },
        [callback, delay]
    );

    return debouncedFunction;
};
