import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonProgressBar,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { ErrorBoundary } from "@rpforms/shared/build";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import db from "../db";

const Calendar = ({ history }) => {
    const [isLoading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        const loadEvents = async () => {
            setLoading(true);
            setEvents(await db.calendarEvents.toArray());
            setTypes(await db.calendarEventTypes.toArray());
            setLoading(false);
        };
        loadEvents();
    }, []);

    const localizer = momentLocalizer(moment);

    const onSelectEvent = (event) => {
        history.push(`/calendarevents/event/${event.event_id}`);
    };

    const eventStyleGetter = (event, start, end, isSelected) => {
        const ausführungsId = types.find((t) => t.name === "Ausführung")?.id ?? 1;
        const type = types.find((t) => t.id === event.calendar_event_type_id);
        const isAusführung =
            event.calendar_event_type_id === ausführungsId || !event.calendar_event_type_id;

        const style = {
            backgroundColor: !isAusführung ? type.color : "#3174ad",
            borderRadius: "0px",
            opacity: 0.8,
            color: "white",
            border: "0px",
            display: "block",
            fontStyle: event.was_submitted ? "italic" : "normal",
        };
        return {
            style,
        };
    };

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Kalender</IonTitle>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/calendarevents"} />
                    </IonButtons>
                </IonToolbar>
                {isLoading && <IonProgressBar color="secondary" type="indeterminate" />}
            </IonHeader>
            <IonContent>
                <ErrorBoundary>
                    {!isLoading && (
                        <BigCalendar
                            localizer={localizer}
                            startAccessor="start"
                            endAccessor="end"
                            eventPropGetter={eventStyleGetter}
                            views={["month", "week", "agenda"]}
                            onSelectEvent={onSelectEvent}
                            style={{
                                height: "90%",
                                width: "96%",
                                marginTop: "1%",
                                marginLeft: "2%",
                            }}
                            events={events.map((event) => ({
                                title: event.name,
                                start: event.scheduled_from,
                                allDay: true,
                                end: event.scheduled_to,
                                event_id: event.id,
                                device_id: event.device_id,
                                calendar_event_type_id: event.calendar_event_type_id,
                            }))}
                        />
                    )}
                </ErrorBoundary>
            </IonContent>
        </>
    );
};

export default Calendar;
