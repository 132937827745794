import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useRef, useState } from "react";
import * as React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { NEW_INVENTORY_ITEM } from "../actions";
import { openEditor } from "../actions/editor";
import { getInventoryItemsAJAX } from "../actions/inventory_items";
import { SmallDangerButton, SmallPrimaryButton } from "../components/Button";
import { EditHeaderAction, EditHeaderGroup } from "../components/EditHeader";
import { FieldWrapper } from "../components/FieldWrapper";
import { MoneyInput } from "../components/layout/MoneyInput";
import { NumberInput } from "../components/layout/NumberInput";
import Textarea from "../components/layout/Textarea";
import { RightAligned } from "../components/universal/PageNavbar";
import { API_URL, COMPANY_ID, FRONTEND_URL } from "../config";
import {
    currentPlatform,
    filterOptions,
    isEmpty,
    positiveNumberKeyDownEvent,
    swapArrayItems,
    template,
    toCentsInt,
    toCurrencyString,
    toDigit,
} from "../utils";
import { BaseField } from "./BaseField";
import { DefaultValue, Description, Hint, Required, Width } from "./configurables";
import { Conditional } from "./configurables/Conditional";
import { LabelInput } from "./inputFields/label";
import { EditOptions } from "./RadioField";

export class InvoicePositionsListField extends BaseField {
    public icon = "edit";
    public fieldType = "Rechnungs-Positionen";
    public attributeNames = ["displayMode", "items", "notice2AutofillTop", "notice2Autofill"];

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [Conditional, Required, Description, DefaultValue, Width, Hint];
    }

    // noinspection JSMethodCanBeStatic
    public EditComponent({ layout, schema, field, entry = null, register, designer }) {
        const save = (items) => {
            schema.setFieldOption(field, "items", items);
            field.items = items;
        };

        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className="powerform-flex">
                    <div className="powerform-w50">
                        <label>Überschrift</label>
                        <LabelInput register={register} field={field} />
                    </div>
                </div>
                <div className="powerform-flex mt-3">
                    <EditOptions
                        defaultValue={field.items}
                        onChange={save}
                        parentHashcode={field.hashCode}
                    />
                </div>
                <div className="powerform-flex mt-3">
                    <div className="powerform-w100">
                        <label>Anzeigen als</label>

                        <div>
                            <input
                                type="radio"
                                ref={register}
                                name={field.inputName("displayMode")}
                                defaultChecked={field.displayMode === "radio"}
                                value="radio"
                            />{" "}
                            Einfache Auswahl{" "}
                            <input
                                style={{
                                    filter: "grayscale(1)",
                                    marginLeft: "40px",
                                }}
                                type="radio"
                                checked={true}
                                readOnly={true}
                            />
                        </div>
                        <div>
                            <input
                                type="radio"
                                ref={register}
                                name={field.inputName("displayMode")}
                                defaultChecked={field.displayMode === "checkboxes"}
                                value="checkboxes"
                            />{" "}
                            Checkboxes
                            <input
                                style={{
                                    filter: "grayscale(1)",
                                    marginLeft: "40px",
                                }}
                                type="checkbox"
                                checked={true}
                                readOnly={true}
                            />{" "}
                            <input
                                style={{ filter: "grayscale(1)" }}
                                type="checkbox"
                                checked={false}
                                readOnly={true}
                            />{" "}
                            <input
                                style={{ filter: "grayscale(1)" }}
                                type="checkbox"
                                checked={false}
                                readOnly={true}
                            />
                        </div>
                        <div>
                            <input
                                type="radio"
                                ref={register}
                                name={field.inputName("displayMode")}
                                defaultChecked={field.displayMode === "switch"}
                                value="switch"
                            />{" "}
                            Schaltflächen
                            <div
                                style={{
                                    display: "inline-block",
                                    marginLeft: "20px",
                                }}
                            >
                                <button style={{ transform: "scale(0.6)" }}>1</button>
                                <button style={{ transform: "scale(0.6)" }}>2</button>
                                <button style={{ transform: "scale(0.6)" }}>3</button>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                ref={register}
                                name={field.inputName("displayMode")}
                                defaultChecked={field.displayMode === "select"}
                                value="select"
                            />{" "}
                            Auswahlliste <select />
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <label>Automatisches Ausfüllen mit (oberes Notizfeld)</label>
                    <input
                        type="text"
                        name={field.inputName("notice2AutofillTop")}
                        className="form-control"
                        defaultValue={field.notice2AutofillTop}
                        ref={register}
                    />
                    <div className="text-muted">Hinweis</div>
                </div>

                <div className="mt-3">
                    <label>Automatisches Ausfüllen mit (unteres Notizfeld)</label>
                    <input
                        type="text"
                        name={field.inputName("notice2Autofill")}
                        className="form-control"
                        defaultValue={field.notice2Autofill}
                        ref={register}
                    />
                    <div className="text-muted">Hinweis</div>
                </div>
            </FieldWrapper>
        );
    }

    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name,
        rawValue,
        variables = {
            subfields: undefined,
            customer: undefined,
            isPrinting: false,
        },
        saveState = {},
        readonly = false,
    }) {
        const isPrinting = variables.isPrinting;
        const local = new Date();
        local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
        const defaultDate = local.toJSON().slice(0, 10);
        const dispatch = useDispatch();
        const formEntryData = useSelector<any, IFormEntry>((state) => state.formEntries?.data);
        const snapshotData = useRef(null);
        const [selectedInventoryItem, setSelectedInventoryItem] = useState({
            stock: null,
        });
        const [optionsKey, setOptionsKey] = useState("key-1");
        const [noticeTopChanged, setNoticeTopChanged] = useState(false);
        const inventoryItemSelect: any = useRef(null);
        const [inventoryItemOptions, setInventoryItemOptions] = useState([]);
        const defaultInvoicePosition = {
            nr: 0,
            title: "",
            description: "",
            qty: 1,
            unit: "repair",
            price: 0.0,
            inventory_item_id: null,
            in_stock: true,
        };
        const defaultDiscount = variables?.customer?.discount || 0;
        const defaultVat = {
            value: 0.19,
            label: "19 % Mwst.",
        };
        const isApp = currentPlatform() === "app";

        const unitOptions = [
            {
                label: "Reparatur",
                value: "repair",
            },
            {
                label: "Service",
                value: "service",
            },
            {
                label: "Verkauf",
                value: "disposal",
            },
        ];

        const defaultData = {
            invoicePositions: [defaultInvoicePosition],
            discount: defaultDiscount,
            vat: defaultVat,
            shippingInCents: 0,
            notice: "",
            notice2: "",
            processDateBegin: defaultDate,
            processDateEnd: null,
            processDateDelimiter: "bis",
            processWorkers: [],
            unitSelection: unitOptions[0],
        };

        const [data, setData] = useState({
            invoicePositions: [],
            shippingInCents: 0,
            discount: 0,
            vat: defaultVat,
            notice: "",
            notice2: "",
            processDateBegin: null,
            processDateEnd: null,
            processDateDelimiter: "bis",
            processWorkers: [],
            unitSelection: unitOptions[0],
        });

        const [unit, setUnit] = useState(unitOptions[0]);

        const vatOptions = [
            {
                value: 0,
                label: "0 % Mwst.",
            },
            {
                value: 0.05,
                label: "5 % Mwst.",
            },
            {
                value: 0.07,
                label: "7 % Mwst.",
            },
            {
                value: 0.16,
                label: "16 % Mwst.",
            },
            defaultVat,
        ];

        const addInvoicePosition = () => {
            const invoicePositions = data.invoicePositions;
            const newNr =
                invoicePositions.length <= 0
                    ? 1
                    : Math.max(...invoicePositions.map((i) => i.nr)) + 1;
            const newInvoicePosition = {
                ...defaultInvoicePosition,
                nr: newNr,
            };
            setData({
                ...data,
                invoicePositions: [...invoicePositions, newInvoicePosition],
            });
        };

        const getInvoicePosition = (index) => {
            return data.invoicePositions.sort((a, b) => a.nr - b.nr)[index];
        };

        const updateInvoicePosition = (index, ipData) => {
            const invoicePositions = data.invoicePositions;
            const newInvoicePosition = { ...getInvoicePosition(index), ...ipData };
            const upd = [
                ...invoicePositions.slice(0, index),
                newInvoicePosition,
                ...invoicePositions.slice(index + 1),
            ];
            setData({ ...data, invoicePositions: upd });
        };

        const onChangeHandler = (inputValue, action, i) => {
            // setSelectedInventoryItem(inputValue);
            const upd = {
                in_stock: true,
                price: getInvoicePosition(i).price,
                title: getInvoicePosition(i).title,
                description: getInvoicePosition(i).description,
                unit: getInvoicePosition(i).unit,
            };
            if (inputValue && inputValue.value) {
                // setAsyncSelectValue(inputValue);
                upd[action.name] = inputValue.value;
                if (isInventoryItem(i) && action.name === "inventory_item_id") {
                    const avail =
                        getInvoicePosition(i).qty <= inputValue.stock || inputValue.stock === null;
                    upd.title = inputValue.label;
                    upd.price = inputValue.price;
                    // upd.in_stock = avail;
                    upd.in_stock = true;
                    upd.unit = inputValue.unit;
                }
            }
            updateInvoicePosition(i, { ...getInvoicePosition(i), ...upd });
        };

        const updatePrice = (event, valueInCents, index) => {
            updateInvoicePosition(index, {
                ...getInvoicePosition(index),
                price: valueInCents,
            });
        };

        const updateQTY = (event, i) => {
            if (isHours(i)) {
                const qty = toDigit(event.target.value.toString());
                updateInvoicePosition(i, {
                    ...getInvoicePosition(i),
                    qty,
                    in_stock: true,
                });
            } else {
                const qty = toDigit(event.target.value);
                const avail = true;
                // qty <= selectedInventoryItem.stock || selectedInventoryItem.stock === null;
                updateInvoicePosition(i, {
                    ...getInvoicePosition(i),
                    qty,
                    in_stock: avail,
                });
            }
        };

        const updateTitle = (event, i) => {
            updateInvoicePosition(i, {
                ...getInvoicePosition(i),
                title: event.target.value,
            });
        };

        const updateDescription = (event, i) => {
            updateInvoicePosition(i, {
                ...getInvoicePosition(i),
                description: event.target.value,
            });
        };

        const isInStock = (i) => {
            return (
                true ||
                !isInventoryItem(i) ||
                selectedInventoryItem.stock === undefined ||
                selectedInventoryItem.stock === null ||
                getInvoicePosition(i).qty <= selectedInventoryItem.stock
            );
        };

        const totalPriceInCents = (i) => {
            let qty = getInvoicePosition(i).qty;
            if (isHours(i)) {
                qty = parseFloat(qty.toString().replace(",", "."));
            }
            const totalInCents = Math.round(getInvoicePosition(i).price * qty);
            return totalInCents;
        };

        const ckEditor = (v, onChange, readOnly = false, toolbars = "") => {
            return (
                <Editor
                    tinymceScriptSrc={"/tinymce/js/tinymce/tinymce.min.js"}
                    onEditorChange={onChange}
                    disabled={readOnly}
                    value={v}
                    init={{
                        height: 150,
                        menubar: false,
                        statusbar: false,
                        plugins: [
                            "charmap",
                            "searchreplace",
                            "visualblocks",
                            "fullscreen",
                            "paste",
                            "code",
                            "custom_templates",
                        ],
                        external_plugins: {
                            custom_templates: `${FRONTEND_URL}/tinymce/plugins/custom_templates.js`,
                        },
                        branding: false,
                        toolbar: readOnly
                            ? ""
                            : "undo redo | " +
                              "bold italic underline backcolor removeformat " +
                              toolbars,
                    }}
                />
            );
        };

        const createInventoryItem = (inputValue, index) => {
            dispatch(
                openEditor({
                    endpoint: `inventory_items`,
                    endpointCompany: false,
                    new: true,
                    preload: {
                        name: inputValue,
                        inventory_type: "repair",
                        sales_price: 0,
                        buying_price: 0,
                        manufacturer: {},
                        category: {},
                        company_id: COMPANY_ID, // TODO: remove this constant
                    },
                    type: NEW_INVENTORY_ITEM,
                    submitCallback: (inventoryItem) => {
                        setOptionsKey(`key${inventoryItem.id}`);
                        if (inventoryItemSelect && inventoryItemSelect.current) {
                            setTimeout(() => {
                                const newItem = {
                                    value: inventoryItem.id,
                                    label: `${inventoryItem.name}`,
                                    category_name: inventoryItem.category_name,
                                    price: inventoryItem.sales_price,
                                    stock: inventoryItem.quantity,
                                    unit: inventoryItem.inventory_type,
                                };
                                setInventoryItemOptions([...inventoryItemOptions, newItem]);
                                onChangeHandler(
                                    newItem,
                                    {
                                        action: "select-option",
                                        option: newItem,
                                        name: "inventory_item_id",
                                    },
                                    index
                                );
                            }, 500);
                        }
                    },
                })
            );
        };

        const isInventoryItem = (i) => {
            return isRepair(i) || isDisposal(i) || isServicePosition(i);
        };

        const isRepair = (i) => {
            return getInvoicePosition(i).unit === "repair";
        };

        const isDisposal = (i) => {
            return getInvoicePosition(i).unit === "disposal";
        };

        const isServicePosition = (i) => {
            return getInvoicePosition(i).unit === "service";
        };

        const isHours = (i) => {
            return getInvoicePosition(i).unit === "hours";
        };

        const handleMoveDown = (index) => {
            const invoicePositions = swapArrayItems(data.invoicePositions, index, index + 1);
            invoicePositions.forEach((ip, i) => {
                invoicePositions[i].nr = i;
            });
            setData({
                ...data,
                invoicePositions,
            });
        };

        const handleMoveUp = (index) => {
            const invoicePositions = swapArrayItems(data.invoicePositions, index, index - 1);
            invoicePositions.forEach((ip, i) => {
                invoicePositions[i].nr = i;
            });
            setData({
                ...data,
                invoicePositions,
            });
        };

        const moveToPosition = (index, position) => {
            const invoicePositions = data.invoicePositions.slice();
            const itemToMove = invoicePositions[index];
            invoicePositions.splice(index, 1);
            if (position >= invoicePositions.length) {
                invoicePositions.push(itemToMove);
            } else {
                invoicePositions.splice(position, 0, itemToMove);
            }

            invoicePositions.forEach((ip, i) => {
                ip.nr = i;
            });
            setData({ ...data, invoicePositions: invoicePositions });
        };

        const handleTrashPosition = (index) => {
            const invoicePositions = data.invoicePositions;
            const newInvoicePositions = invoicePositions.slice(0);
            newInvoicePositions.splice(index, 1);
            setData({ ...data, invoicePositions: newInvoicePositions });
        };

        const updateShippingInCents = (event, maskedvalue) => {
            setData({ ...data, shippingInCents: maskedvalue });
        };

        const updateDiscount = (event, maskedvalue) => {
            const valueInFloat = toCentsInt(maskedvalue);
            setData({ ...data, discount: valueInFloat });
        };

        const sumInCents = () => {
            const invoicePositions = data.invoicePositions;
            if (!invoicePositions) {
                return 0;
            }
            return invoicePositions.reduce((total, invoicePosition) => {
                return total + invoicePosition.qty * invoicePosition.price;
            }, 0.0);
        };

        const discountInCents = () => {
            if (!data || !data.discount) {
                return 0;
            }
            return (sumInCents() * data.discount) / 100.0;
        };

        const vatTotalInCents = () => {
            if (!data || !data.vat) {
                return 0;
            }
            return (sumInCents() - discountInCents()) * data.vat.value;
        };

        const totalInCents = () => {
            return sumInCents() - discountInCents() + vatTotalInCents() + data.shippingInCents;
        };

        const inventoryPositionsInStock = () => {
            return true;
            // const invoicePositions = data.invoicePositions;
            // if (!invoicePositions) {
            //     return true;
            // }
            // return !invoicePositions
            //     .map(
            //         invoicePosition =>
            //             invoicePosition.unit !== "inventory_item" || invoicePosition.in_stock
            //     )
            //     .includes(false);
        };

        const hidePrices = typeof (window as any).Capacitor !== "undefined";

        const displayTotals = () => {
            if (hidePrices) {
                return;
            }
            return (
                <Table striped bordered hover size="sm">
                    <tbody>
                        <tr>
                            <td style={{ minWidth: "144px" }}>Zwischensumme</td>
                            <td>
                                <MoneyInput min={0} disabled value={sumInCents()} />
                            </td>
                        </tr>
                        <tr className={data.discount <= 0 ? "hidden-print" : ""}>
                            <td>Rabatt</td>
                            <td>
                                <NumberInput
                                    disabled={readonly}
                                    value={data.discount.toString()}
                                    onChange={updateDiscount}
                                    onKeyDown={positiveNumberKeyDownEvent}
                                    min="0"
                                    unit="%"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Select
                                    isDisabled={readonly}
                                    value={data.vat}
                                    options={vatOptions}
                                    name="vat"
                                    onChange={(option) =>
                                        setData({
                                            ...data,
                                            vat: option,
                                        })
                                    }
                                    styles={{
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </td>
                            <td>
                                <MoneyInput value={vatTotalInCents()} disabled min={0} />
                            </td>
                        </tr>
                        <tr className={data.shippingInCents === 0 ? "hidden-print" : ""}>
                            <td>Versand</td>
                            <td>
                                <MoneyInput
                                    disabled={readonly}
                                    value={data.shippingInCents}
                                    onChange={updateShippingInCents}
                                    min={0}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Gesamtsumme</td>
                            <td>
                                <MoneyInput min={0} disabled value={totalInCents()} />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            );
        };

        const init = (v) => {
            if (v) {
                try {
                    const d = JSON.parse(v);
                    if (!d.processDateBegin) {
                        d.processDateBegin = defaultData.processDateBegin;
                    }
                    if (!d.processDateEnd) {
                        d.processDateEnd = defaultData.processDateEnd;
                    }
                    if (!d.processWorkers) {
                        d.processWorkers = defaultData.processWorkers;
                    }
                    if (d.unitSelection) {
                        setUnit(d.unitSelection);
                    }
                    // convert old vat label
                    if (d.vat && d.vat.label.match(/\d+\s%/) && !d.vat.label.includes("Mwst")) {
                        d.vat.label = d.vat.label + " Mwst.";
                    }
                    setData((current) => {
                        return d;
                    });
                } catch (e) {
                    console.error(e);
                }
            } else {
                defaultData.notice = notice2Autofill(
                    data.processDateBegin,
                    data.processDateEnd,
                    data.processDateDelimiter,
                    data.processWorkers,
                    "top"
                );
                setData(defaultData);
            }
        };

        useEffect(() => {
            init(rawValue);
            getInventoryItemsAJAX({}, ["repair", "disposal", "service"]).then((response) => {
                setInventoryItemOptions(
                    filterOptions(
                        "",
                        response.data.inventory_items.map((ii) => {
                            return {
                                value: ii.id,
                                label: `${ii.name}`,
                                category_name: ii.category_name,
                                price: ii.sales_price,
                                stock: ii.quantity,
                                unit: ii.inventory_type,
                            };
                        })
                    )
                );
            });
        }, []);

        useEffect(() => {
            if (formEntryData?.snapshot_nr) {
                setTimeout(() => {
                    // wait until snapshot data is set
                    init(snapshotData.current.value);
                }, 200);
            }
        }, [formEntryData]);

        if (!field.items) {
            field.items = [];
        }

        const notice2Autofill = (
            processDateBegin,
            processDateEnd,
            processDateDelimiter,
            processWorkers,
            position = "bottom"
        ) => {
            let text = data.notice2;
            if (!isEmpty(field.notice2Autofill)) {
                text = field.notice2Autofill;
                variables.subfields = {
                    dateBegin: processDateBegin,
                    dateEnd: processDateEnd,
                    delimiter: processDateDelimiter,
                    options: (processWorkers || []).map((item) => item.label).join(", "),
                };
                // Kundenwunsch: conditional delimiter depedning on present dateEnd
                if (isEmpty(variables.subfields.dateEnd)) {
                    variables.subfields.delimiter = "";
                }
                // text = text.replace("{subfield:date}", processDate);
                // text = text.replace("{subfield:options}", );
                text = template(text, variables, field.formName);
            }
            let textTop = data.notice;
            if (!isEmpty(field.notice2AutofillTop)) {
                if (!noticeTopChanged) {
                    textTop = field.notice2AutofillTop;
                }
                variables.subfields = {
                    dateBegin: processDateBegin,
                    dateEnd: processDateEnd,
                    delimiter: processDateDelimiter,
                    options: (processWorkers || []).map((item) => item.label).join(", "),
                };
                // Kundenwunsch: conditional delimiter depedning on present dateEnd
                if (isEmpty(variables.subfields.dateEnd)) {
                    variables.subfields.delimiter = "";
                }
                // text = text.replace("{subfield:date}", processDate);
                // text = text.replace("{subfield:options}", );
                textTop = template(textTop, variables, field.formName);
            }
            if (position === "top") {
                return textTop;
            }
            return text;
        };

        return (
            <>
                {data && (
                    <input type={"hidden"} name={field.hashCode} value={JSON.stringify(data)} />
                )}
                {data && (
                    <input
                        type={"hidden"}
                        name={field.hashCode + "-snapshot-slot"}
                        ref={snapshotData}
                    />
                )}
                <Table striped bordered hover size="sm" className="price-table">
                    <thead>
                        <tr>
                            <th style={{ width: "6%" }}>&nbsp;</th>
                            <th style={{ width: "2%" }}>Pos.</th>
                            <th>Produkt / Service</th>
                            <th style={{ width: "8%" }}>Menge</th>
                            {!hidePrices && (
                                <th style={isApp ? { width: "14%" } : { width: "18%" }}>Preis</th>
                            )}
                            {!hidePrices && (
                                <th style={isApp ? { width: "14%" } : { width: "18%" }}>Gesamt</th>
                            )}
                            <th style={{ width: "3%" }}>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.invoicePositions &&
                            data.invoicePositions
                                .sort((a, b) => a.nr - b.nr)
                                .map((invoicePosition, index) => (
                                    <tr key={`invoice-position-${index}`}>
                                        <td className={""}>
                                            {!(isApp || readonly) && (
                                                <EditHeaderGroup>
                                                    <EditHeaderAction
                                                        type="button"
                                                        className="edit-header-action"
                                                        onClick={() => handleMoveUp(index)}
                                                    >
                                                        <i
                                                            className="fa fa-arrow-up"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </EditHeaderAction>
                                                    <EditHeaderAction
                                                        type="button"
                                                        className="edit-header-action"
                                                        onClick={() => handleMoveDown(index)}
                                                    >
                                                        <i
                                                            className="fa fa-arrow-down"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </EditHeaderAction>
                                                </EditHeaderGroup>
                                            )}
                                        </td>
                                        <td className={""} style={{ maxWidth: "5rem" }}>
                                            <input
                                                style={{ maxWidth: "3rem" }}
                                                defaultValue={index + 1}
                                                onBlur={(e) => {
                                                    moveToPosition(
                                                        index,
                                                        parseInt(e.target.value) - 1
                                                    );
                                                    e.target.value = (index + 1).toString();
                                                }}
                                            />
                                        </td>
                                        <td className={""}>
                                            <div
                                                style={{
                                                    display: "none",
                                                    textAlign: "left",
                                                    padding: "2px",
                                                }}
                                                className={"pdf-visible"}
                                            >
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: invoicePosition.title,
                                                    }}
                                                    className={"pdf-visible"}
                                                />
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: invoicePosition.description,
                                                    }}
                                                    className={"pdf-visible"}
                                                />
                                            </div>
                                            {(window as any).EDIT_MODE &&
                                                isEmpty(invoicePosition.title) && (
                                                    <CreatableSelect
                                                        // value={asyncSelectValue}
                                                        isDisabled={isApp || readonly}
                                                        id={"inventory-item-select"}
                                                        ref={inventoryItemSelect}
                                                        name="inventory_item_id"
                                                        styles={{
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                zIndex: 100,
                                                            }),
                                                            menu: (provided, state) => ({
                                                                ...provided,
                                                                zIndex: 100,
                                                            }),
                                                            menuList: (provided, state) => ({
                                                                ...provided,
                                                                zIndex: 100,
                                                            }),
                                                        }}
                                                        onChange={(inputValue, action) =>
                                                            onChangeHandler(
                                                                inputValue,
                                                                action,
                                                                index
                                                            )
                                                        }
                                                        placeholder={"auswählen..."}
                                                        isSearchable
                                                        options={inventoryItemOptions}
                                                        key={"key-1"}
                                                        onCreateOption={(inputValue) => {
                                                            return createInventoryItem(
                                                                inputValue,
                                                                index
                                                            );
                                                        }}
                                                        /*loadOptions={(inputValue) => {
                                                    return promiseInventoryItemOptions(
                                                        ["repair", "disposal", "service"],
                                                        inputValue
                                                    );
                                                }}*/
                                                    />
                                                )}
                                            {(window as any).EDIT_MODE &&
                                                !isEmpty(invoicePosition.title) && (
                                                    <input
                                                        id={`title-${index}`}
                                                        type="text"
                                                        value={invoicePosition.title}
                                                        name="title"
                                                        onChange={(e) => updateTitle(e, index)}
                                                        className={"form-control"}
                                                        disabled={isApp || readonly}
                                                    />
                                                )}
                                            <Textarea
                                                id={"description-" + index}
                                                autogrow={true}
                                                type="text"
                                                name="description"
                                                onUpdate={(e) => updateDescription(e, index)}
                                                className={"form-control pdf-hidden"}
                                                style={{ display: "none" }}
                                                value={invoicePosition.description}
                                                readOnly={isApp || readonly}
                                            />
                                            {invoicePosition.unit && (
                                                <Select
                                                    components={{
                                                        Control: () => null,
                                                    }}
                                                    defaultValue={
                                                        unitOptions.find(
                                                            (uo) =>
                                                                uo.value === invoicePosition.unit
                                                        ) || unitOptions[0]
                                                    }
                                                    options={unitOptions}
                                                    name="unit"
                                                    onChange={(inputValue, action) =>
                                                        onChangeHandler(inputValue, action, index)
                                                    }
                                                    styles={{
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                                />
                                            )}
                                            <div
                                                id={`cke_editor_wrapper${index}`}
                                                className={"pdf-hidden"}
                                            >
                                                {ckEditor(
                                                    invoicePosition.description,
                                                    (value, editor) => {
                                                        setData((currentData) => {
                                                            const invoicePositions = currentData.invoicePositions.sort(
                                                                (a, b) => a.nr - b.nr
                                                            );
                                                            const ipData = {
                                                                description: value,
                                                            };
                                                            const newInvoicePosition = {
                                                                ...invoicePositions[index],
                                                                ...ipData,
                                                            };
                                                            const upd = [
                                                                ...invoicePositions.slice(0, index),
                                                                newInvoicePosition,
                                                                ...invoicePositions.slice(
                                                                    index + 1
                                                                ),
                                                            ];
                                                            return {
                                                                ...currentData,
                                                                invoicePositions: upd,
                                                            };
                                                        });
                                                    },
                                                    isApp || readonly
                                                )}
                                            </div>
                                        </td>
                                        <td className={""}>
                                            {isHours(index) && (
                                                <input
                                                    type="text"
                                                    pattern="[0-9]*[\.,]?[0,5]?"
                                                    value={invoicePosition.qty}
                                                    name="qty"
                                                    onChange={(e) => updateQTY(e, index)}
                                                    className={"form-control"}
                                                    disabled={isApp || readonly}
                                                />
                                            )}
                                            {!isHours(index) && (
                                                <input
                                                    type="number"
                                                    value={invoicePosition.qty}
                                                    min="1"
                                                    step="1"
                                                    name="qty"
                                                    onChange={(e) => updateQTY(e, index)}
                                                    className={
                                                        isInStock(index)
                                                            ? "form-control"
                                                            : "form-control is-invalid"
                                                    }
                                                    disabled={isApp || readonly}
                                                />
                                            )}
                                        </td>
                                        {!hidePrices && (
                                            <td className={""}>
                                                <MoneyInput
                                                    disabled={readonly}
                                                    value={invoicePosition.price} // Value in cents
                                                    min={0}
                                                    onChange={(e, value) =>
                                                        updatePrice(e, value, index)
                                                    }
                                                    name="price_netto"
                                                />
                                            </td>
                                        )}
                                        {!hidePrices && (
                                            <td className="">
                                                <MoneyInput
                                                    value={totalPriceInCents(index)} // Value in cents
                                                    min={0}
                                                    name="price_brutto"
                                                    disabled
                                                />
                                            </td>
                                        )}
                                        <td className={""}>
                                            {!(isApp || readonly) && (
                                                <RightAligned>
                                                    <SmallPrimaryButton
                                                        hidden={true}
                                                        onClick={addInvoicePosition}
                                                    ></SmallPrimaryButton>
                                                    <SmallDangerButton
                                                        onClick={() => handleTrashPosition(index)}
                                                    >
                                                        <i className={"fa fa-trash"}></i>
                                                    </SmallDangerButton>
                                                </RightAligned>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                    </tbody>
                </Table>
                <div style={{ pageBreakInside: "avoid" }}>
                    <Row>
                        <Col xs={7} md={6}>
                            <div>
                                <Row>
                                    {!readonly && (
                                        <Col xs={12} md={3}>
                                            <SmallPrimaryButton
                                                type="button"
                                                onClick={addInvoicePosition}
                                            >
                                                Position hinzufügen
                                            </SmallPrimaryButton>
                                        </Col>
                                    )}
                                    <Col className={"was-validated"}>
                                        <div
                                            className={
                                                inventoryPositionsInStock()
                                                    ? "invalid-feedback"
                                                    : "invalid-feedback d-inline-block"
                                            }
                                        >
                                            Bitte überprüfe die Mengenangaben
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "20px" }}>
                                    <Col xs={12} md={10}>
                                        {!(window as any).EDIT_MODE && (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: data.notice,
                                                }}
                                                className={"pdf-visible"}
                                            />
                                        )}
                                        {(window as any).EDIT_MODE &&
                                            ckEditor(
                                                data.notice,
                                                (value, editor) => {
                                                    setNoticeTopChanged(true);
                                                    setData((currentData) => {
                                                        return {
                                                            ...currentData,
                                                            notice: value,
                                                        };
                                                    });
                                                },
                                                isApp || readonly,
                                                "custom_templates"
                                            )}
                                    </Col>
                                </Row>
                                {(window as any).EDIT_MODE && (
                                    <Row>
                                        <Col xs={12} md={4} style={{ textAlign: "right" }}>
                                            Die Arbeiten wurden ausgeführt am
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <input
                                                readOnly={readonly}
                                                type={"date"}
                                                name={field.hashCode + "-date"}
                                                className="form-control"
                                                defaultValue={data.processDateBegin}
                                                onChange={(event) => {
                                                    const d = event.target.value;
                                                    setData({
                                                        ...data,
                                                        processDateBegin: d,
                                                        notice: notice2Autofill(
                                                            d,
                                                            data.processDateEnd,
                                                            data.processDateDelimiter,
                                                            data.processWorkers,
                                                            "top"
                                                        ),
                                                        notice2: notice2Autofill(
                                                            d,
                                                            data.processDateEnd,
                                                            data.processDateDelimiter,
                                                            data.processWorkers
                                                        ),
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {(window as any).EDIT_MODE && (
                                    <Row>
                                        <Col xs={12} md={4} style={{ textAlign: "right" }}>
                                            <input
                                                disabled={readonly}
                                                className="form-control"
                                                defaultValue={data.processDateDelimiter}
                                                onChange={(event) => {
                                                    const d = event.target.value;
                                                    setData({
                                                        ...data,
                                                        processDateDelimiter: d,
                                                        notice: notice2Autofill(
                                                            data.processDateBegin,
                                                            data.processDateEnd,
                                                            d,
                                                            data.processWorkers,
                                                            "top"
                                                        ),
                                                        notice2: notice2Autofill(
                                                            data.processDateBegin,
                                                            data.processDateEnd,
                                                            d,
                                                            data.processWorkers
                                                        ),
                                                    });
                                                }}
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <input
                                                readOnly={readonly}
                                                type={"date"}
                                                name={field.hashCode + "-date-end"}
                                                className="form-control"
                                                defaultValue={data.processDateEnd}
                                                onChange={(event) => {
                                                    const d = event.target.value;
                                                    setData({
                                                        ...data,
                                                        processDateEnd: d,
                                                        notice: notice2Autofill(
                                                            data.processDateBegin,
                                                            d,
                                                            data.processDateDelimiter,
                                                            data.processWorkers,
                                                            "top"
                                                        ),
                                                        notice2: notice2Autofill(
                                                            data.processDateBegin,
                                                            d,
                                                            data.processDateDelimiter,
                                                            data.processWorkers
                                                        ),
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {(window as any).EDIT_MODE && (
                                    <Row>
                                        <Col xs={12} md={4} style={{ textAlign: "right" }}>
                                            durch:
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <div>
                                                {layout.wrapComponent(
                                                    <div
                                                        className={layout.wrapComponentCSS(
                                                            field.configureCSS()
                                                        )}
                                                    >
                                                        {layout.wrapFieldset(
                                                            <>
                                                                <Select
                                                                    value={data.processWorkers}
                                                                    isDisabled={readonly}
                                                                    onChange={(opts) => {
                                                                        setData({
                                                                            ...data,
                                                                            processWorkers: opts,
                                                                            notice: notice2Autofill(
                                                                                data.processDateBegin,
                                                                                data.processDateEnd,
                                                                                data.processDateDelimiter,
                                                                                opts,
                                                                                "top"
                                                                            ),
                                                                            notice2: notice2Autofill(
                                                                                data.processDateBegin,
                                                                                data.processDateEnd,
                                                                                data.processDateDelimiter,
                                                                                opts
                                                                            ),
                                                                        });
                                                                    }}
                                                                    isMulti
                                                                    styles={{
                                                                        menu: (provided) => ({
                                                                            ...provided,
                                                                            zIndex: 9999,
                                                                        }),
                                                                    }}
                                                                    isClearable
                                                                    options={field.items.map(
                                                                        (item) => {
                                                                            return {
                                                                                label: item.name,
                                                                                value: item.name,
                                                                            };
                                                                        }
                                                                    )}
                                                                />
                                                                {layout.wrapHint(
                                                                    field.rawOptions.hint ? (
                                                                        <p>
                                                                            {field.rawOptions.hint}
                                                                        </p>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col xs={12} md={10}>
                                        {!(window as any).EDIT_MODE && (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: data.notice2,
                                                }}
                                                className={"pdf-visible"}
                                            />
                                        )}
                                        {(window as any).EDIT_MODE &&
                                            ckEditor(
                                                data.notice2,
                                                (value, editor) => {
                                                    setData((currentData) => {
                                                        return {
                                                            ...currentData,
                                                            notice2: value,
                                                        };
                                                    });
                                                },
                                                isApp || readonly
                                            )}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={5} md={6} style={{ paddingLeft: "5px" }}>
                            {displayTotals()}
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

    public PresenterComponent({ field }: { field: any }): any {
        return (
            <div>
                <label>
                    <strong>{field.label}:</strong>
                </label>
                <div>
                    <img width={300} src={field.rawValue} />
                </div>
            </div>
        );
    }

    public PDFComponent({ field, styles }) {
        return <></>;
    }
}
