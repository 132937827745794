import { IonItem, IonLabel, IonRow, IonText } from "@ionic/react";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import eventTime from "../hooks/eventTime";

const IonEventItem = styled<any>(IonItem)`
    transition: opacity ease-in;

    h2 {
        color: #000;
        font-size: 18px;
        font-weight: bold;
    }

    p {
        color: #666;
    }

    ${(props) =>
        props.was_completed &&
        css`
            --background: rgba(33, 33, 33, 0.2);
            opacity: 0.6;
        `}
`;

const Dot = styled<any>(IonItem)`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${(props) => props.color || "#00F"};
    margin-right: 5px;
`;

export const CalendarEventListItem = ({ event, type }) => {
    const outstandingTasksLength = event.device_tasks.length;

    const eventItem = (
        <IonEventItem was_completed={event.was_completed}>
            <IonLabel style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                <IonText>
                    <strong>{event.name} </strong>
                    {(!type || type.name === "Ausführung") &&
                        !event.was_completed &&
                        `(${outstandingTasksLength} Aufgabe${
                            outstandingTasksLength === 1 ? "" : "n"
                        })`}
                    <IonText style={{ fontSize: "13px" }}>
                        <IonRow style={{ alignItems: "center" }}>
                            <Dot color={type?.color || "#3174ad"} />
                            {type?.name || "Ausführung"}
                        </IonRow>
                    </IonText>
                    <IonText style={{ fontSize: "13px" }}>
                        {eventTime(event, type?.name === "Ausführung")}
                    </IonText>
                </IonText>
            </IonLabel>
        </IonEventItem>
    );

    const editable = !event.was_completed || window.OPEN_SUBMITTED;

    return (
        <>
            {editable ? (
                <Link to={`/calendarevents/event/${event.id}`}>{eventItem}</Link>
            ) : (
                <> {eventItem} </>
            )}
        </>
    );
};

export default CalendarEventListItem;
