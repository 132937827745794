import { IonProgressBar } from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import db from "../db";
import downArrow from "../images/down-arrow.png";
import upArrow from "../images/up-arrow.png";
import CalendarEventListItem from "./CalendarEventListItem";

const AccordionButton = styled.div`
    background-color: ${(props) => props.color};
    padding: 13px 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const AccordionArrow = styled.img`
    width: 20px;
    height: 20px;
`;

const findEventType = (eventTypes, event) => {
    return eventTypes.find((type) => type.id === event.calendar_event_type_id);
};

export function CalendarEventAccordion() {
    const [eventTypes, setEventTypes] = useState([]);
    const [events, setEvents] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [todaysEvents, setTodaysEvents] = useState([]);
    const [tomorrowsEvents, setTomorrowsEvents] = useState([]);
    const [otherEvents, setOtherEvents] = useState([]);

    // contains all lists that are currently shown
    const [openLists, setOpenLists] = useState([0, 1, 2]);

    const handleButtonClick = (id) => {
        if (openLists.includes(id)) {
            return setOpenLists(openLists.filter((listId) => listId !== id));
        }
        setOpenLists([...openLists, id]);
    };

    useEffect(() => {
        if (!events) { return; }
        setTodaysEvents(
            events.filter((e) => {
                return moment().isBetween(
                    moment(e.scheduled_from),
                    moment(e.scheduled_to),
                    "day",
                    "[]"
                );
            })
        );
        setTomorrowsEvents(
            events.filter((e) => {
                return moment().add(1, "days").isSame(moment(e.scheduled_from), "day");
            })
        );
        setOtherEvents(
            events.filter((e) => {
                return (
                    !moment().isBetween(
                        moment(e.scheduled_from),
                        moment(e.scheduled_to),
                        "day",
                        "[]"
                    ) && !moment().add(1, "days").isSame(moment(e.scheduled_from), "day")
                );
            })
        );
    }, [events]);

    useEffect(() => {
        const loadEvents = async () => {
            setLoading(true);
            setEvents(await db.calendarEvents.toArray());
            setEventTypes(await db.calendarEventTypes.toArray());
            setLoading(false);
        };
        loadEvents();
    }, []);

    const sort = (list: any[]) => {
        return list.sort((eventA, eventB) => {
            const timeA = +Date.parse(eventA.scheduled_from);
            const timeB = +Date.parse(eventB.scheduled_from);
            if (timeA > timeB) {
                return 1;
            }
            if (timeA === timeB) {
                return 0;
            }
            if (timeA < timeB) {
                return -1;
            }
        });
    };

    return (
        <div style={{ paddingBottom: "50px" }}>
            {isLoading && <IonProgressBar color="secondary" type="indeterminate" />}
            <AccordionButton onClick={() => handleButtonClick(0)} color="#AFA">
                HEUTE ({moment().format("DD.MM.YYYY")}){" "}
                <AccordionArrow src={openLists.includes(0) ? upArrow : downArrow} />{" "}
            </AccordionButton>
            {openLists.includes(0) &&
                sort(todaysEvents).map((e) => (
                    <CalendarEventListItem
                        type={findEventType(eventTypes, e)}
                        event={e}
                        key={e.id}
                    />
                ))}
            <AccordionButton onClick={() => handleButtonClick(1)} color="#FDA">
                MORGEN ({moment().add(1, "days").format("DD.MM.YYYY")}){" "}
                <AccordionArrow src={openLists.includes(1) ? upArrow : downArrow} />{" "}
            </AccordionButton>
            {openLists.includes(1) &&
                sort(tomorrowsEvents).map((event) => {
                    return (
                        <CalendarEventListItem
                            type={findEventType(eventTypes, event)}
                            event={event}
                            key={event.id}
                        />
                    );
                })}
            <AccordionButton onClick={() => handleButtonClick(2)} color="#DDD">
                ANDERE <AccordionArrow src={openLists.includes(2) ? upArrow : downArrow} />{" "}
            </AccordionButton>
            {openLists.includes(2) &&
                sort(otherEvents).map((event) => {
                    return (
                        <CalendarEventListItem
                            type={findEventType(eventTypes, event)}
                            event={event}
                            key={event.id}
                        />
                    );
                })}
        </div>
    );
}
