import React, { useState, useEffect } from "react";

interface MoneyInputProps {
    value: number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>, valueInCents: number) => void;
    disabled?: boolean;
    name?: string;
    min?: number;
}

export const MoneyInput = ({ value, onChange, disabled, name, min }: MoneyInputProps) => {
    const [inputValue, setInputValue] = useState("");
    const inputRef = React.useRef(null);

    const formatValue = (valueInCents) => {
        const valueInEuros = valueInCents / 100;
        return new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
        }).format(valueInEuros);
    };

    const parseValue = (formattedValue) => {
        let numericString = formattedValue.replace(/[^0-9.,-]/g, "");
        numericString = numericString.replace(",", "|");
        numericString = numericString.replace(/\./g, "");
        numericString = numericString.replace("|", ".");

        const valueInEuros = parseFloat(numericString);
        if (isNaN(valueInEuros)) {
            return 0;
        }
        return Math.round(valueInEuros * 100);
    };

    const countSeparatorsBefore = (str, position) => {
        const beforeCursor = str.slice(0, position);
        const dots = (beforeCursor.match(/\./g) || []).length;
        return dots;
    };

    useEffect(() => {
        setInputValue(formatValue(value));
    }, [value]);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        const currentCursorPosition = e.target.selectionStart;
        const oldValue = inputValue;
        const oldNumericValue = parseValue(oldValue);

        // Store the number of separators before the cursor in the old value
        const oldSeparatorCount = countSeparatorsBefore(oldValue, currentCursorPosition);

        // Ensure euro sign is present
        if (!newValue.includes("€")) {
            const euroSignIndex = oldValue.indexOf("€");
            if (euroSignIndex !== -1) {
                const beforeEuro = newValue.slice(0, euroSignIndex);
                const afterEuro = newValue.slice(euroSignIndex);
                const restoredValue = beforeEuro + "€" + afterEuro;
                setInputValue(restoredValue);

                setTimeout(() => {
                    const input = inputRef.current;
                    if (input) {
                        input.setSelectionRange(currentCursorPosition, currentCursorPosition);
                    }
                }, 0);
                return;
            }
        }

        const oldCommaIndex = oldValue.indexOf(",");
        const newCommaIndex = newValue.indexOf(",");
        const isRemovingComma = oldCommaIndex !== -1 && newCommaIndex === -1;

        if (isRemovingComma) {
            // Prevent the comma deletion by restoring the old value
            setInputValue(oldValue);
            setTimeout(() => {
                const input = inputRef.current;
                if (input) {
                    input.setSelectionRange(currentCursorPosition, currentCursorPosition);
                }
            }, 0);
            return;
        }

        // Validate the numeric format
        const numericPart = newValue.replace(/[^0-9.,-]/g, "");
        if (numericPart.length > 0) {
            const commaCount = (numericPart.match(/,/g) || []).length;
            if (commaCount > 1) return;

            if (commaCount === 1) {
                const parts = numericPart.split(",");
                if (parts[1] && parts[1].length > 2) return;
            }
        }

        setInputValue(newValue);

        if (onChange) {
            const valueInCents = parseValue(newValue);
            // Only trigger onChange if the numeric value actually changed
            if (valueInCents !== oldNumericValue) {
                onChange(e, valueInCents);
            }
        }

        // Calculate the new cursor position based on the difference in separators
        setTimeout(() => {
            const input = inputRef.current;
            if (input) {
                const newSeparatorCount = countSeparatorsBefore(newValue, currentCursorPosition);
                const separatorDiff = newSeparatorCount - oldSeparatorCount;
                const newPosition = currentCursorPosition + separatorDiff;
                input.setSelectionRange(newPosition, newPosition);
            }
        }, 0);
    };

    const handleKeyDown = (e) => {
        const euroSign = "€";
        const selectionStart = e.target.selectionStart;
        const selectionEnd = e.target.selectionEnd;

        // Prevent deletion of euro sign
        if (
            (e.key === "Backspace" || e.key === "Delete") &&
            inputValue.slice(selectionStart - 1, selectionEnd + 1).includes(euroSign)
        ) {
            e.preventDefault();
            return;
        }

        // Prevent deletion of comma
        const commaIndex = inputValue.indexOf(",");
        if (commaIndex !== -1) {
            if (e.key === "Backspace" && selectionStart === commaIndex + 1) {
                e.preventDefault();
                return;
            }
            if (e.key === "Delete" && selectionStart === commaIndex) {
                e.preventDefault();
                return;
            }
        }
    };

    const handleBlur = (e) => {
        const valueInCents = parseValue(inputValue);
        const formattedValue = formatValue(valueInCents);
        setInputValue(formattedValue);

        // Store cursor position before blur
        const currentPosition = e.target.selectionStart;
        const oldSeparatorCount = countSeparatorsBefore(inputValue, currentPosition);
        const newSeparatorCount = countSeparatorsBefore(formattedValue, currentPosition);
        const separatorDiff = newSeparatorCount - oldSeparatorCount;

        // Restore cursor position after formatting
        setTimeout(() => {
            const input = inputRef.current;
            if (input) {
                const newPosition = currentPosition + separatorDiff;
                input.setSelectionRange(newPosition, newPosition);
            }
        }, 0);
    };

    if (disabled) {
        return <input disabled value={formatValue(value)} className="form-control" />;
    }

    return (
        <input
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            name={name}
            min={min}
            className="form-control"
        />
    );
};
