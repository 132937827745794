import {
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonIcon,
    IonProgressBar,
    IonSpinner,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import {ErrorBoundary} from "@rpforms/shared/build";
import {useLiveQuery} from "dexie-react-hooks";
import {checkmarkCircle, closeCircle, cloudUpload} from "ionicons/icons";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps} from "react-router";
import {fetchCalendarEventsRemotely} from "../actions/calendarEvents";
import {fetchCalendarEventTypesRemotely} from "../actions/calendarEventTypes";
import {fetchEntitiesRemotely} from "../actions/entities";
import {setError} from "../actions/errors";
import {fetchFormGroupsRemotely} from "../actions/formGroups";
import {fetchFormsRemotely} from "../actions/forms";
import {upload} from "../actions/sync";
import {CenteredLoader} from "../components/CenteredLoader";
import db from "../db";
import "./Sync.css";

const sortByType = (type) => (a, b) => {
    if (a.type === type && b.type === type) {
        return 0;
    }

    if (a.type === type && b.type !== type) {
        return -1;
    }

    if (a.type !== type && b.type === type) {
        return 1;
    }

    return 0;
};

const Sync: React.FunctionComponent<RouteComponentProps> = ({history}) => {
    const syncUpdate = useSelector<any>((state) => state.sync.update);
    const jobs = useLiveQuery(() => db.sync.toArray(), [syncUpdate]);
    const [status, setStatus] = useState("");
    const [syncing, setSyncing] = useState(false);

    const dispatch = useDispatch();
    const sortedJobs = jobs?.sort(sortByType("uploadAsset")) || [];

    const syncUploads = async () => {
        setSyncing(true);
        try {
            setStatus("Formulare & Events hochladen...");
            await dispatch(upload(sortedJobs));
        } catch (e) {
            setStatus("");
            dispatch(setError(e.message));
        } finally {
            setStatus("");
            setSyncing(false);
        }
    };

    const deleteTask = (task) => {
        if (
            !window.confirm(
                "Sind Sie sicher dieses Protokoll zu löschen? Es kann danach nicht wiederhergestellt werden."
            )
        ) {
            return;
        }

        return db.sync.delete(task.id);
    };

    const sync = async () => {
        setSyncing(true);
        try {
            setStatus("Objekte synchronisieren...");
            await (dispatch(fetchEntitiesRemotely()) as unknown as Promise<any>);
            setStatus("Formulargruppen synchronisieren...");
            await (dispatch(fetchFormGroupsRemotely()) as unknown as Promise<any>);
            setStatus("Formulare synchronisieren...");
            await (dispatch(fetchFormsRemotely()) as unknown as Promise<any>);
            setStatus("Kalenderevents herunterladen...");
            await (dispatch(fetchCalendarEventTypesRemotely()) as unknown as Promise<any>);
            await (dispatch(fetchCalendarEventsRemotely()) as unknown as Promise<any>);
            setStatus("Formulare & Events hochladen...");
            await (dispatch(upload(sortedJobs)) as unknown as Promise<any>);
            setStatus("");
            return;
        } catch (e) {
            setStatus("");
            dispatch(setError(e.message));
        } finally {
            setStatus("");
            setSyncing(false);
        }
    };

    const taskStyle = (task) => {
        if (task.isCompleted) {
            return "task-success";
        }

        if (task.inProgress) {
            return "task-running";
        }

        if (task.isError) {
            return "task-error";
        }

        return "task-idle";
    };

    if (!jobs) {
        return <CenteredLoader/>;
    }

    const loading = syncing;
    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Synchronisation</IonTitle>
                    {loading && (
                        <IonSpinner style={{marginRight: "20px"}}
                                    slot="end"></IonSpinner>
                    )}

                    {!loading && (
                        <IonIcon
                            icon={checkmarkCircle}
                            className="icon-green"
                            size={"64"}
                            slot={"end"}
                        />
                    )}
                </IonToolbar>
                {loading &&
                    <IonProgressBar color="success" type="indeterminate"/>}
            </IonHeader>
            <IonContent>
                <ErrorBoundary>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        {sortedJobs.length === 0 && (
                            <div
                                className="ion-align-items-center ion-justify-content-center ion-padding"
                                style={{paddingTop: "40px"}}
                            >
                                {!loading && <h2>Alles erledigt.</h2>}
                            </div>
                        )}
                        <div style={{paddingTop: "15px"}}>
                            <IonButton disabled={syncing} onClick={sync}
                                       color={"success"}>
                                {syncing && (
                                    <IonSpinner
                                        style={{
                                            width: "16px",
                                            marginRight: "5px",
                                        }}
                                    />
                                )}{" "}
                                Alles synchronisieren
                            </IonButton>
                            <IonButton disabled={syncing} onClick={syncUploads}>
                                Formulare & Events übertragen
                            </IonButton>
                        </div>
                    </div>
                    {status && (
                        <>
                            <hr/>
                            <h3
                                style={{
                                    padding: "20px",
                                    textAlign: "center",
                                }}
                            >
                                {status}
                            </h3>
                        </>
                    )}
                    <hr/>
                    {sortedJobs.map((task, index) => {
                        return (
                            <IonCard className={taskStyle(task)} key={task.id}>
                                <IonCardHeader>
                                    <IonCardTitle
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div>
                                            <IonIcon
                                                style={{
                                                    paddingRight: "10px",
                                                    position: "relative",
                                                    top: "2px",
                                                }}
                                                icon={cloudUpload}
                                            />
                                            {task.title}
                                        </div>
                                        {task.isError && (
                                            <div>
                                                <IonIcon
                                                    style={{
                                                        paddingRight: "10px",
                                                        position: "relative",
                                                        top: "2px",
                                                    }}
                                                    onClick={() => deleteTask(task)}
                                                    icon={closeCircle}
                                                ></IonIcon>
                                            </div>
                                        )}
                                    </IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        );
                    })}
                </ErrorBoundary>
            </IonContent>
        </>
    );
};

export default Sync;
