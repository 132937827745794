import { SET_ERROR, UNSET_ERROR } from "./types";

export const setError = (message) => {
    return {
        type: SET_ERROR,
        payload: message,
    };
};

export const unsetError = () => {
    return { type: UNSET_ERROR };
};
