import { ADD_SYNC_JOB, RESET_SYNC_JOB, SET_SYNC_JOBS, UPDATE_SYNC_JOB } from "../actions/types";

const initialState = {
    queue: [],
    update: null,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_SYNC":
            return { ...state, update: +new Date() };
        case ADD_SYNC_JOB:
            return { ...state, queue: [...state.queue, action.payload] };

        case SET_SYNC_JOBS:
            return { ...state, queue: [...state.queue, action.payload] };
        case UPDATE_SYNC_JOB:
            return {
                ...state,
                queue: state.queue.map((task) => {
                    if (task.id === action.payload.id) {
                        return { ...task, ...action.payload };
                    }
                    return task;
                }),
            };
        case RESET_SYNC_JOB:
            return {
                ...state,
                queue: state.queue.map((task) => {
                    return { ...task, isError: false, inProgress: false };
                }),
            };
    }

    return state;
}
