import { Capacitor, Plugins } from "@capacitor/core";
import {
    IonApp,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonToast,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import { calendar, home, settings, sync } from "ionicons/icons";
import React from "react";
import "react-dates/initialize";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import styled from "styled-components";
import { UNSET_ERROR } from "./actions/types";
import Entities from "./pages/Entities";
import EntityDetails from "./pages/EntityDetails";

/* Theme variables */
import Calendar from "./pages/Calendar";
import CalendarEventDetails from "./pages/CalendarEventDetails";
import CalendarEvents from "./pages/CalendarEvents";
import Settings from "./pages/Settings";
import StorageSettings from "./pages/Storage";
import Sync from "./pages/Sync";
import TaskDetails from "./pages/TaskDetails";
import configureStore from "./store";

import { IAssetManager } from "@rpforms/shared/build";
import { AssetManager } from "./AssetManager";
import { StorageDialog } from "./components/StorageDialog";
import db from "./db";
import "./theme/variables.css";

const { Keyboard } = Plugins;

export const { store, persistor } = configureStore(undefined);

declare global {
    // tslint:disable-next-line:interface-name
    interface Window {
        OPEN_SUBMITTED: boolean;
        PRELOAD: boolean;
        ONE_BY_ONE: boolean;
        EDIT_MODE: boolean;
        SKIP_SYNC_STORAGE: boolean;
        IGNORE_SETTINGS_WARNING: boolean;
        AssetManager: IAssetManager;
    }
}

if (Capacitor.platform !== "web") {
    Keyboard.setAccessoryBarVisible({ isVisible: false });
}

window.addEventListener("keyboardDidShow", (e) => {
    setTimeout(() => {
        (document.activeElement as any).scrollIntoViewIfNeeded();
    }, 100);
});

window.AssetManager = new AssetManager();
window.IGNORE_SETTINGS_WARNING = false;
window.SKIP_SYNC_STORAGE = localStorage.syncStorage === "true";
window.OPEN_SUBMITTED = localStorage.openSubmitted === "true";

const ErrorToast = styled(IonToast)`
    --background: rgba(255, 65, 65, 0.4);
`;

const ErrorDialogs = () => {
    const dispatch = useDispatch();
    const error = useSelector<any, any>((state) => state.errors);
    const dismissError = () => {
        dispatch({
            type: UNSET_ERROR,
        });
    };

    return (
        <>
            {error.message && (
                <ErrorToast
                    isOpen={true}
                    onDidDismiss={dismissError}
                    message={error.message}
                    duration={2000}
                    showCloseButton={true}
                />
            )}
        </>
    );
};

Sentry.init({
    dsn: "https://09d7046d326c49349bdc650c0db0aa35@o791122.ingest.sentry.io/4504094669078528",
    integrations: [
        new BrowserTracing({
            tracingOrigins: [
                "https://api.rpforms.kega.network",
                "https://api.staging.rpforms.kega.network",
            ],
        }),
    ],

    release: "rp-app@" + process.env.REACT_APP_COMMIT,
    environment: process.env.REACT_APP_ENVIRONMENT,
    tracesSampleRate: 0.1,
});

(window as any).PRELOAD = true;
(window as any).EDIT_MODE = true; // TODO: remove globals
const App: React.FunctionComponent = () => {
    (window as any).db = db;
    Sentry.setUser({ username: "iPad", id: `ipad-${localStorage.deviceID}` });
    return (
        <IonApp>
            <IonReactRouter>
                <IonTabs>
                    <IonRouterOutlet>
                        <Provider store={store}>
                            <ErrorDialogs />
                            <StorageDialog />
                            <Route
                                path="/:tab(entities)/entity/:entity_id"
                                component={EntityDetails}
                                exact={true}
                            />
                            <Route
                                path="/:tab(settings)/storage"
                                component={StorageSettings}
                                exact={true}
                            />
                            <Route
                                path="/:tab(entities)/entity/:entity_id/:task_id"
                                component={TaskDetails}
                                exact={true}
                            />
                            <Route
                                path="/:tab(calendarevents)/calendar"
                                component={Calendar}
                                exact={true}
                            />
                            <Route
                                path="/:tab(calendarevents)/event/:event_id"
                                component={CalendarEventDetails}
                                exact={true}
                            />

                            <Route path="/:tab(entities)" component={Entities} exact={true} />
                            <Route
                                path="/:tab(calendarevents)"
                                component={CalendarEvents}
                                exact={true}
                            />
                            <Route path="/:tab(sync)" component={Sync} exact={true} />
                            <Route path="/:tab(settings)" component={Settings} exact={true} />
                            <Route
                                path="/"
                                render={() => <Redirect to="/entities" />}
                                exact={true}
                            />
                        </Provider>
                    </IonRouterOutlet>

                    <IonTabBar slot="bottom">
                        <IonTabButton tab="calendarevents" href="/calendarevents">
                            <IonIcon icon={calendar} />
                            <IonLabel>Kalenderevents</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="entities" href="/entities">
                            <IonIcon icon={home} />
                            <IonLabel>Objekte</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="sync" href="/sync">
                            <IonIcon icon={sync} />
                            <IonLabel>Synchronisieren</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="settings" href="/settings">
                            <IonIcon icon={settings} />
                            <IonLabel>Einstellungen</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </IonReactRouter>
        </IonApp>
    );
};

export default App;
