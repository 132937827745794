import { IonAlert } from "@ionic/react";
import React, { useState } from "react";

const LeistungsnachweisAlert = () => {
    const [isOpen, setIsOpen] = useState(true);
    const confirmButtonProps = {
        text: "Verstanden",
        onClick: () => setIsOpen(false),
    };

    return (
        <IonAlert
            isOpen={isOpen}
            header="Bilder und Signatur"
            message={
                "Bitte mindestens 2 Bilder hochladen, sowie die Auftraggeber Signatur einholen"
            }
            buttons={[confirmButtonProps]}
        />
    );
};

export default LeistungsnachweisAlert;
