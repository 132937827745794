import {
    IonBackButton,
    IonBadge,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonProgressBar,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import {ErrorBoundary} from "@rpforms/shared/build";
import {
    calendar,
    document,
    filing,
    home,
    paper,
    paperPlane,
    quote,
    refresh
} from "ionicons/icons";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {MAX_STORAGE_IN_MB, WARN_STORAGE_IN_MB} from "../config";
import db from "../db";

const tables = [
    "sync",
    "tasks",
    "forms",
    "formEntries",
    "formGroups",
    "entities",
    "calendarEvents",
];

export const calculateInnoDBSize = async () => {
    let usage = 0;
    const perTable = {};

    for await (const table of tables) {
        perTable[table] = 0;
        await db[table].each((item, cursor) => {
        if (table === "tasks"){
            console.log(item)
        }
            const size = JSON.stringify(item).length * 1.10;
            usage += size;
            perTable[table] += size;
        });
    }

    return {
        usage,
        perTable,
        quota: MAX_STORAGE_IN_MB * 1024 * 1024,
    };
};

const format = (val) => {
    if (!val) { return `0 MB`; }

    if (val > 1024 * 1024 * 1024){
     return `${(val / 1024 / 1024 / 1024).toFixed(2)} GB`;
    }

    if (val > 1024 * 1024){
        return `${(val / 1024 / 1024).toFixed(2)} MB`;
    }

    if (val > 1024){
        return `${(val / 1024).toFixed(2)} KB`;
    }

    return `${(val).toFixed(2)} B`;
};

const StorageSettings: React.FunctionComponent = () => {
    const [usedQuota, setUsedQuota] = useState(1);
    const [maxQuota, setMaxQuota] = useState(1);
    const [dialog, setDialog] = useState(window.IGNORE_SETTINGS_WARNING);
    const [skipSync, setSkipSync] = useState(window.SKIP_SYNC_STORAGE);
    const [openSubmitted, setOpenSubmitted] = useState(window.OPEN_SUBMITTED);
    const [oneByOne, setOneByOne] = useState(window.ONE_BY_ONE);

    const [perTable, setPerTable] = useState({
        entities: 0,
        forms: 0,
        formEntries: 0,
        tasks: 0,
        sync: 0,
        formGroups: 0,
        calendarEvents: 0,
    });

    useEffect(() => {
        const fetchStorage = async () => {
            const quota = await calculateInnoDBSize();
            setUsedQuota(quota.usage);
            setMaxQuota(quota.quota);
            setPerTable(quota.perTable as any);
            console.log(quota.perTable);
        };


        fetchStorage();
    }, []);

    const btomb = (bytes) => {
        return (bytes / 1024 / 1024).toFixed(0);
    };

    const percentageUsed = usedQuota / maxQuota;
    const toggleDialog = () => {
        localStorage.ignoreSettingsWarning = 1;
        setDialog(!dialog);
        window.IGNORE_SETTINGS_WARNING = !dialog;
    };

    const toggleSyncStorage = () => {
        localStorage.syncStorage = !skipSync;
        setSkipSync(!skipSync);
        window.SKIP_SYNC_STORAGE = !skipSync;
    };

    const toggleOpenSubmitted = () => {
        localStorage.openSubmitted = !openSubmitted;
        setOpenSubmitted(!openSubmitted);
        window.OPEN_SUBMITTED = !openSubmitted;
    };

    const toggleOneByOne = () => {
        localStorage.oneByOne = !oneByOne;
        setOneByOne(!oneByOne);
        window.ONE_BY_ONE = !oneByOne;
    };

    const storageItems = [
        {icon: paperPlane, label: "Formulare", storage: perTable.forms},
        {icon: document, label: "Protokolle", storage: perTable.tasks},
        {icon: refresh, label: "Sync", storage: perTable.sync},
        {icon: paper, label: "Aufträge", storage: perTable.formGroups},
        {icon: filing, label: "Aufgaben", storage: perTable.formEntries},
        {icon: home, label: "Objekte", storage: perTable.entities},
        {icon: calendar, label: "Kalendar", storage: perTable.calendarEvents},

    ];

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/settings"}/>
                    </IonButtons>
                    <IonTitle>Speicher</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <ErrorBoundary>
                    <div style={{padding: "40px"}}>
                        <IonProgressBar color="primary" value={percentageUsed}/>
                        {usedQuota && (
                            <>
                                Sie verwenden {btomb(usedQuota)}MB von
                                maximalen {btomb(maxQuota)}
                                MB.
                            </>
                        )}
                    </div>

                    <IonList>
                        {storageItems.map(item => {
                            return <IonItem>
                                <IonIcon slot="start" icon={item.icon}/>
                                <IonLabel>{item.label}</IonLabel>
                                <IonBadge
                                    slot="end"
                                    color={
                                        (item.storage ?? 0) / 1024 / 1024 >
                                        WARN_STORAGE_IN_MB
                                            ? "danger"
                                            : "default"
                                    }
                                >
                                    ca. {format(item.storage)}
                                </IonBadge>
                            </IonItem>;
                        })}
                        <IonItem>
                            <IonLabel>Speicherwarnung ignorieren</IonLabel>
                            <IonCheckbox
                                slot="start"
                                onIonChange={(e: any) => toggleDialog()}
                                defaultChecked={dialog}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel>Speicher beim Synchronisieren nicht
                                benutzen</IonLabel>
                            <IonCheckbox
                                slot="start"
                                onIonChange={(e: any) => toggleSyncStorage()}
                                checked={skipSync}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel>One by One</IonLabel>
                            <IonCheckbox
                                slot="start"
                                onIonChange={(e: any) => toggleOneByOne()}
                                checked={oneByOne}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel>Abgeschlossene Protokolle
                                öffnen</IonLabel>
                            <IonCheckbox
                                slot="start"
                                onIonChange={(e: any) => toggleOpenSubmitted()}
                                checked={openSubmitted}
                            />
                        </IonItem>
                    </IonList>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </ErrorBoundary>
            </IonContent>
        </>
    );
};

export default StorageSettings;
