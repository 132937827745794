import {
    Filesystem,
    FilesystemDirectory,
    FilesystemEncoding
} from "@capacitor/core";
import {IAsset, IAssetManager} from "@rpforms/shared/build";

export class AssetManager implements IAssetManager {
    public async write(asset: IAsset, data: string) {
        return Filesystem.writeFile({
            path: asset.path,
            data,
            directory: FilesystemDirectory.Data,
            encoding: FilesystemEncoding.UTF8,
        });
    }

    public async delete(asset: IAsset){
        const deleteResult = await Filesystem.deleteFile({
            path: asset.path,
            directory: FilesystemDirectory.Data,
        });
        
        console.log(deleteResult);
        return;
    }

    public async toBlob(asset: IAsset) {
        const file = await Filesystem.readFile({
            path: asset.path,
            directory: FilesystemDirectory.Data,
            encoding: FilesystemEncoding.UTF8,
        });

        return file.data;
    }

    public async url(asset: IAsset) {
        if (asset.uploaded) {
            return asset.url;
        }

        return Filesystem.getUri({
            directory: FilesystemDirectory.Data,
            path: asset.path,
        });
    }
}

declare global {
    // tslint:disable-next-line:interface-name
    interface Window {
        AssetManager: IAssetManager;
    }
}
