import {
    ADD_FORM,
    DELETE_FORM,
    FORM_CREATE,
    GET_FORMS,
    SET_FORMS,
    START_LOADING,
    STOP_LOADING,
    UPDATE_FORM,
} from "../actions/types";

const initialState = {
    forms: [],
    isLoading: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ADD_FORM:
            return {
                ...state,
                forms: [
                    action.payload,
                    ...state.forms.filter((form) => form.id !== action.payload.id),
                ],
            };
        case SET_FORMS:
            return {
                ...state,
                forms: action.payload,
            };
        case GET_FORMS:
            return { ...state, forms: action.payload };
        case DELETE_FORM:
            return {
                ...state,
                forms: state.forms.filter((form) => {
                    return form.id !== action.payload.id;
                }),
            };
        case FORM_CREATE:
            return { ...state, forms: [action.payload, ...state.forms] };
        case START_LOADING:
            return { ...state, isLoading: true };
        case STOP_LOADING:
            return { ...state, isLoading: false };
        case UPDATE_FORM:
            const forms = state.forms.map((form) => {
                if (form.id === action.payload.id) {
                    return action.payload;
                }
                return form;
            });
            return { ...state, forms };
    }

    return state;
}
