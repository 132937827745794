import localforage from "localforage";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore } from "redux-persist";
import thunkMiddleware from "redux-thunk";
import { STORAGE_REDUX_PERSIST } from "./constants";
import rootReducer from "./reducers";

localforage.config({
    driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
    name: STORAGE_REDUX_PERSIST,
});

export default function configureStore(preloadedState) {
    const middlewares = [thunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const enhancers: any = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    const store: any = createStore(rootReducer, preloadedState, composedEnhancers);
    const persistor = persistStore(store);
    return { store, persistor };
}
