const initialState = {
    showStorageDialog: false,
    ignore: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case "SHOW_STORAGE_DIALOG":
            return { ...state, showStorageDialog: true };
        case "HIDE_STORAGE_DIALOG":
            return { ...state, showStorageDialog: false };
    }

    return state;
}
