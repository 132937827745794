import { IonButton, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import { Link } from "react-router-dom";

export const CalendarEventEntityListItem = ({ showFormSelector, entity }) => {
    const address = entity.address;
    const addForm = (e) => {
        showFormSelector();
        e.preventDefault();
        e.stopPropagation();
        return false;
    };

    return (
        <Link to={`/entities/entity/${entity.id}`}>
            <IonItem>
                <IonLabel>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            {entity.customer.name} - {entity.name} (Objektnr: {entity.entity_nr})
                        </div>
                        <IonButton onClick={(e) => addForm(e)}>Formular hinzufügen</IonButton>
                    </div>{" "}
                    {address && (
                        <div>
                            <strong>Adresse:</strong> {address.street}, {address.postalcode}{" "}
                            {address.city}
                        </div>
                    )}
                    <p>
                        {entity.contact_name ? (
                            <>
                                <strong>Ansprechpartner:</strong> {entity.contact_name}{" "}
                            </>
                        ) : (
                            ""
                        )}
                        {entity.contact_phone ? " • Tel.: " + entity.contact_phone : ""}
                        {entity.contact_email ? " • E-mail: " + entity.contact_email : ""}
                    </p>
                </IonLabel>
            </IonItem>
        </Link>
    );
};

export default CalendarEventEntityListItem;
