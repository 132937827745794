import moment from "moment";
import {
    ADD_TASK,
    COMPLETE_TASK,
    LOADING_TASKS,
    MERGE_REMOTE_TASKS,
    REMOVE_TASK,
    SET_TASKS,
    STOP_LOADING_TASKS,
    UPDATE_TASK,
} from "../actions/types";

const initialState = {
    tasks: [],
    isLoading: false,
};

const filterBy = (list: any[], prop: string) => {
    return list.reduce((prev: any, cur: any) => {
        if (!prev.find((b: any) => cur[prop] === b[prop])) {
            return [...prev, cur];
        }
        return prev;
    }, []);
};

export default function(state = initialState, action) {
    switch (action.type) {
        case LOADING_TASKS:
            return { ...state, isLoading: true };
        case MERGE_REMOTE_TASKS:
            return {
                ...state,
                tasks: state.tasks.filter((task) => {
                    if (typeof task.id === "string") {
                        return true;
                    }

                    return task.saveState || !!action.payload.find((t) => t.id === task.id);
                }),
            };
        case SET_TASKS:
            return {
                ...state,
                tasks: filterBy([...state.tasks, ...action.payload], "id")
                    .map((task) => {
                        // coming from remote api
                        if (!task.startFormatted || !task.endFormatted) {
                            task.startFormatted = moment(task.start).format("DD.MM.YYYY");
                            task.endFormatted = moment(task.end).format("DD.MM.YYYY");
                            task.start = Date.parse(task.start);
                            task.end = Date.parse(task.end);
                        }

                        if (task.form) {
                            task.form_id = task.form.id;
                            delete task.form;
                        }

                        if (task.entity) {
                            task.entity_id = task.entity.id;
                            delete task.entity;
                        }

                        return task;
                    })
                    .filter((task) => {
                        if (task.saveState) {
                            return true;
                        }

                        if (!task.start || !task.end) {
                            return true;
                        }

                        return task.start <= +new Date() && task.end >= +new Date();
                    }),
            };
        case REMOVE_TASK:
            return {
                ...state,
                tasks: state.tasks.filter(({ id }) => {
                    return String(id) !== String(action.payload.id);
                }),
            };
        case UPDATE_TASK:
            return {
                ...state,
                tasks: state.tasks.map((t) => {
                    if (t.id === action.payload.id) {
                        // remove these relations
                        delete action.payload.form;
                        delete action.payload.entity;

                        return { ...t, ...action.payload };
                    }
                    return t;
                }),
            };
        case ADD_TASK:
            return { ...state, tasks: [...state.tasks, action.payload] };
        case COMPLETE_TASK:
            return {
                ...state,
                tasks: state.tasks.map((task) => {
                    if (task.id === action.payload.id) {
                        task.was_submitted = true;
                    }
                    return task;
                }),
            };
        case STOP_LOADING_TASKS:
            return { ...state, isLoading: false };
    }

    return state;
}
