import React, { useEffect, useState } from "react";
import db from "../db";
import { TaskStatus } from "./TaskItem";

export const EntityTaskCount = ({ entity }) => {
    const [taskCount, setTaskCount] = useState(0);

    useEffect(() => {
        const loadTaskCount = async () => {
            const count = await db.tasks
                .where("entity_id")
                .equals(entity.id)
                .and((task) => task.status !== TaskStatus.SUBMITTED)
                .count();

            setTaskCount(count);
        };
        loadTaskCount();
    }, [entity]);

    return <span>{taskCount}</span>;
};
