import axios from "axios";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { API_URL, COMPANY_ID } from "../config";
import { sharedHistory } from "../history";
import { defaultOpts, getCollection, user } from "./actions_helper";
import {
    ADD_ENTITY,
    CREATE_ENTITY,
    DELETE_ENTITY,
    ENTITIES_AUTOCOMPLETE,
    ENTITIES_AUTOCOMPLETE_START,
    GET_ENTITIES,
    UPDATE_ENTITY,
} from "./types";

export function getEntities(page = 1, cb = (entities) => {}) {
    return async (dispatch: IDispatch<IReduxAction<JSON>>) => {
        const response = await axios.get(
            `${API_URL}/entities.json?sort=a-z&page=${page}&hidden_fields=company,customer,device_task.form.form_data`,
            {
                headers: { authorization: user().token },
            }
        );
        dispatch({
            type: GET_ENTITIES,
            payload: response.data,
        });
        cb(response.data);
        return response.data;
    };
}

export function getEntityAJAX(query: string, limit?: number) {
    return axios.get(`${API_URL}/companies/${COMPANY_ID}/autocomplete/entities`, {
        headers: { authorization: user().token },
        params: { q: query, limit },
    });
}

export function createEntityAJAX(entity: any) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .post(`${API_URL}/entities.json`, {
                entity: { ...entity, company_id: COMPANY_ID },
            })
            .then((res) => {
                dispatch({
                    type: CREATE_ENTITY,
                    payload: res.data,
                });
            })
            .catch((err) => {
                throw err;
            });
}

export function deleteEntity(entity: any, cb = (data) => {}) {
    return (dispatch: IDispatch<IReduxAction<any>>) => {
        axios
            .delete(`${API_URL}/entities/${entity.id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: DELETE_ENTITY,
                    payload: response.data,
                });
                cb(response.data);
            });
    };
}

export function renameEntity(entity: any, { title }: { title: string }) {
    return (dispatch: IDispatch<IReduxAction<JSON>>) => {
        axios
            .put(`${API_URL}/entities/${entity.id}.json`, {
                headers: { authorization: user().token },
                title,
            })
            .then((response) => {
                dispatch({
                    type: UPDATE_ENTITY,
                    payload: response.data,
                });
                sharedHistory.push("/objects");
            });
    };
}

export function createEntity(company: any, entity: any) {
    return (dispatch: IDispatch<CallHistoryMethodAction<any>>) => {
        return axios
            .post(
                `${API_URL}/entities.json`,
                { entity: { ...entity, company_id: company.id } },
                {
                    headers: { authorization: user().token },
                }
            )
            .then((response) => {
                dispatch(push("/master_data/objects"));
                return response.data;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function entityByID(id: number) {
    return (dispatch: IDispatch<IReduxAction<JSON>>) =>
        axios
            .get(`${API_URL}/entities/${id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({ type: ADD_ENTITY, payload: response.data });
            })
            .catch((err) => {
                throw err;
            });
}

export function fetchEntities(page = 1, cb: any = () => {}, opts = defaultOpts, pageSize = "all") {
    opts.page_size = pageSize;
    opts.page = page;
    return getCollection("entities", opts, cb);
}

export function fetchEntitiesForAutocomplete(cb = null, suffix = "") {
    return (dispatch) => {
        dispatch({
            type: ENTITIES_AUTOCOMPLETE_START,
        });
        return axios
            .get(`${API_URL}/companies/${COMPANY_ID}/autocomplete/entities/${suffix}`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: ENTITIES_AUTOCOMPLETE,
                    payload: response.data,
                });
                if (cb) {
                    cb(response.data);
                }
                return response.data;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function getEntitiesForMasterData() {
    return (dispatch) => {
        return axios
            .get(`${API_URL}/companies/${COMPANY_ID}/autocomplete/entities?with=customers`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: ENTITIES_AUTOCOMPLETE,
                    payload: response.data,
                });
                return response.data;
            })
            .catch((err) => {
                throw err;
            });
    };
}
