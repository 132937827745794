import {
    IonButton,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";

export const SubmissionNameModal = ({ submitEntry, cancel, defaultName }) => {
    const [name, setName] = useState(defaultName);
    return (
        <IonModal backdropDismiss={false} isOpen={true}>
            <IonToolbar>
                <IonTitle>Formular abschließen</IonTitle>
            </IonToolbar>
            <IonContent>
                <div className={"p5"}>
                    <IonItem>
                        <IonLabel title={"Protokollname"} position={"floating"}>
                            Protokollname
                        </IonLabel>
                        <IonInput
                            value={name}
                            readonly={true}
                            placeholder="Form Name"
                            name={"formName"}
                            onIonChange={(e: any) => setName(e.target.value)}
                        />
                    </IonItem>
                </div>
                <div className={"flex justify-content-center"}>
                    <IonButton onClick={() => cancel()}>Abbrechen</IonButton>
                    <IonButton onClick={() => submitEntry(name)}>Abschließen</IonButton>
                </div>
            </IonContent>
        </IonModal>
    );
};
