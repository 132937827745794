import moment from "moment";
import {
    ADD_ENTITY,
    CREATE_ENTITY,
    DELETE_ENTITY,
    GET_ENTITIES,
    LOADING_ENTITIES,
    SET_ENTITIES,
    START_LOADING,
    STOP_LOADING,
    STOP_LOADING_ENTITIES,
    UPDATE_ENTITY,
} from "../actions/types";

const initialState = {
    entities: [],
    isLoading: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case LOADING_ENTITIES:
            return { ...state, isLoading: true };
        case SET_ENTITIES:
            return {
                ...state,
                entities: action.payload.sort((a, b) => {
                    if (!a.name) {
                        return 0;
                    }
                    if (!b.name) {
                        return 0;
                    }
                    const textA = a.name.toUpperCase();
                    const textB = b.name.toUpperCase();

                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                }),
            };
        case STOP_LOADING_ENTITIES:
            return { ...state, isLoading: false };
        case ADD_ENTITY:
            return { ...state, entities: [action.payload, ...state.entities] };
        case GET_ENTITIES:
            return { ...state, entities: action.payload };
        case DELETE_ENTITY:
            return {
                ...state,
                entities: state.entities.filter((entity) => {
                    return !(entity.id === action.payload.id);
                }),
            };
        case CREATE_ENTITY:
            return { ...state, entities: [action.payload, ...state.entities] };

        case START_LOADING:
            return { ...state, isLoading: true };
        case STOP_LOADING:
            return { ...state, isLoading: false };
        case UPDATE_ENTITY:
            const entities = state.entities.map((entity) => {
                if (entity.id === action.payload.id) {
                    return action.payload;
                }
                return entity;
            });
            return { ...state, entities };
    }

    return state;
}
