import {
    IonContent,
    IonHeader,
    IonLabel,
    IonList,
    IonProgressBar,
    IonSearchbar,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { ErrorBoundary } from "@rpforms/shared/build";
import React, { useEffect, useState } from "react";
import { EntityListItem } from "../components/EntityListItem";
import db from "../db";
import { useGroupedEntities } from "../hooks/useGroupedEntities";

enum ViewMode {
    FORMS_ONLY = "FORMS_ONLY",
    ALL = "ALL",
}

const Entities: any = () => {
    const [isLoading, setLoading] = useState(false);
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.FORMS_ONLY);
    const [entitiesStore, setEntitiesStore] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [entities, entitiesWithForms] = useGroupedEntities(entitiesStore, [viewMode]);

    const getFilteredEntities = entities.filter((entity) => {
        return (
            entity.name.toUpperCase().includes(searchText.toUpperCase()) ||
            entity.customer.name.toUpperCase().includes(searchText.toUpperCase())
        );
    });

    const getMatchingEntitiesByViewMode = (): IEntity[] => {
        if (viewMode === ViewMode.FORMS_ONLY) { return entitiesWithForms; }
        if (searchText) { return getFilteredEntities; }
        return entities;
    };

    const displayMatchingEntities = () => {
        const matchingEntities = getMatchingEntitiesByViewMode();
        return matchingEntities.map((entity) => <EntityListItem entity={entity} key={entity.id} />);
    };

    useEffect(() => {
        const loadEntities = async () => {
            setLoading(true);
            setEntitiesStore(await db.entities.toArray());
            setLoading(false);
        };

        loadEntities();
    }, []);

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Objekte </IonTitle>
                </IonToolbar>
                {isLoading && <IonProgressBar color="secondary" type="indeterminate" />}
            </IonHeader>
            <IonContent>
                <ErrorBoundary>
                    <IonSegment
                        onIonChange={(e) => setViewMode(e.detail.value as ViewMode)}
                        value={viewMode}
                    >
                        <IonSegmentButton
                            defaultChecked={viewMode === ViewMode.FORMS_ONLY}
                            value={ViewMode.FORMS_ONLY}
                        >
                            <IonLabel>Nur mit Formularen</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton
                            defaultChecked={viewMode === ViewMode.FORMS_ONLY}
                            value={ViewMode.ALL}
                        >
                            <IonLabel>Alle Objekte</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                    <IonList>
                        {viewMode === ViewMode.ALL && (
                            <IonSearchbar
                                value={searchText}
                                onIonChange={(e) => setSearchText(e.detail.value!)}
                                showCancelButton="always"
                            ></IonSearchbar>
                        )}
                        <div className="results-container">
                            {displayMatchingEntities()}
                            <br />
                            <br />
                        </div>
                    </IonList>
                </ErrorBoundary>
            </IonContent>
        </>
    );
};

export default Entities;
