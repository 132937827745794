import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar,
} from "@ionic/react";

import { calendar } from "ionicons/icons";
import React from "react";
import { CalendarEventAccordion } from "../components/CalendarEventsAccordion";

const CalendarEvents = () => {
    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Kalenderevents</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="primary" href="/calendarevents/calendar">
                            <IonIcon style={{ paddingRight: "5px" }} icon={calendar} /> Kalender
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <CalendarEventAccordion />
            </IonContent>
        </>
    );
};

export default CalendarEvents;
