import { uniqueID } from "@rpforms/shared/build";
import { SchemaEntry } from "@rpforms/shared/build/spec/SchemaEntry";
import { TaskStatus } from "../components/TaskItem";
import config, { API_URL, axiosClient } from "../config";
import db from "../db";
import { uniqueNumber } from "../utils";
import { SET_FORMS } from "./types";

export const setForms = (payload) => {
    return {
        type: SET_FORMS,
        payload,
    };
};

export const submitDraftForm = ({ task }: { task: ITask }) => {
    return async (dispatch) => {
        const jobData = {
            name: task.name,
            device_id: config.DEVICE_ID,
            form_id: task.form_id,
            task_id: task.id,
            entity_id: task.entity_id,
            form_data: task.saveState,
        };

        const job = {
            id: uniqueID(),
            title: "Formularübtragung " + task.name,
            description: "Formular übertragen",
            state: "idle",
            type: "uploadForm",
            data: jobData,
        };

        await db.sync.put(job);
        await db.tasks.put({ ...task, status: TaskStatus.IN_QUEUE });
    };
};

export function submitForm({
    name = "",
    formData,
    schemaBuilder,
    params,
    task,
    form,
    check = true,
}) {
    return async (dispatch) => {
        const formRef = document.querySelector("form");
        if (check && formRef && !formRef.checkValidity()) {
            alert("Das Formular ist noch nicht komplett ausgefüllt.");
            formRef.reportValidity();
            return;
        }

        const elements: any = [
            ...(document as any).querySelectorAll(".form-container #form [name]"),
        ];

        const entry = new SchemaEntry(schemaBuilder, formData);

        const job = {
            id: uniqueNumber(),
            title: "Formularübtragung " + (task.name || "#" + task.id),
            description: "Formular übertragen",
            type: "uploadForm",
            data: {
                name,
                device_id: config.DEVICE_ID,
                task_id: task.id,
                form_id: (form as any).id,
                form_name: (form as any).name,
                entity_id: task.entity_id,
                form_data: entry.originalValues,
            },
        };

        await db.sync.put(job);
        await db.tasks.put({ ...task, status: TaskStatus.IN_QUEUE });
    };
}

export const fetchFormsRemotely = () => {
    return async (dispatch) => {
        await db.forms.where("id").above(0).delete();
        const response = await axiosClient.get(`/forms.json`);
        const data = response.data;
        if (!data.length) {
            return;
        }
        await db.forms.bulkPut(data);
    };
};
