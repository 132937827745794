import * as Sentry from "@sentry/react";
import {AssetManager} from "../AssetManager";
import config from "../config";
import db from "../db";
import {RESET_SYNC_JOB} from "./types";

export const resetJob = () => (dispatch) =>
    dispatch({
        type: RESET_SYNC_JOB,
    });

export const upload = (jobs) => async (dispatch) => {
    for await (const job of jobs) {
        if (!(window as any).SKIP_SYNC_STORAGE) {
            await db.sync.put({
                ...job,
                inProgress: true,
            });
        }

        dispatch({type: "UPDATE_SYNC"});
        const assetManager = new AssetManager();
        try {
            if (job.type === "uploadAsset") {
                const request = await fetch(`${config.ENDPOINT_URL}/form_entry_assets.json`, {
                    method: "post",
                    headers: {
                        "X-JOB-ID": job.id,
                        "Content-Type": "application/json",
                        "authorization": config.DEVICE_TOKEN,
                    },
                    body: JSON.stringify({
                        data: job.data,
                        image: await assetManager.toBlob(job.data),
                    }),
                }).then(async (s) => {
                    if (s.status === 200) {
                        db.tasks.update(job.id, {status: 4});
                        await assetManager.delete(job.data);
                        await db.sync.put({
                            ...job,
                            isCompleted: true,
                            inProgress: false,
                            isError: false,
                        });
                    }
                });

                continue;
            }
            const req = await fetch(
                job.type === "uploadForm"
                    ? `${config.ENDPOINT_URL}/forms/${job.data.form_id}/form_entries.json`
                    : `${config.ENDPOINT_URL}/calendar_events/${job.event_id}.json`,
                {
                    method: job.type === "uploadForm" ? "post" : "PATCH",
                    headers: {
                        "X-JOB-ID": job.id,
                        "Content-Type": "application/json",
                        "authorization": config.DEVICE_TOKEN,
                    },
                    body: JSON.stringify(job.data),
                }
            );
            const data = await req.json();
            if (data.id && req.status === 200) {
                if (!(window as any).SKIP_SYNC_STORAGE) {
                    await db.sync.put({
                        ...job,
                        isCompleted: true,
                        inProgress: false,
                        isError: false,
                    });
                }

                try {
                    if (job.type === "uploadForm") {
                        const task = await db.tasks.get(job.data.task_id);

                        if (!(window as any).SKIP_SYNC_STORAGE) {
                            if (task) {
                                task.calendar_event_id
                                    ? await db.tasks.update(task.id, {status: 4})
                                    : await db.tasks.delete(task.id);
                            }
                        }
                    }

                    if (job.type === "uploadEvent") {
                        if (!(window as any).SKIP_SYNC_STORAGE) {
                            await db.tasks
                                .where("calendar_event_id")
                                .equals(job.event_id)
                                .and((task) => task.status === 4)
                                .delete();
                            await db.calendarEvents.delete(job.event_id);
                        }
                    }
                } catch (e) {
                    throw new Error(e);
                }
            } else {
                if (data.error) {
                    alert(data.error);
                }

                throw new Error(data.error);
            }
        } catch (e) {
            // Sentry.withScope((scope) => {
            //     scope.setContext("job", {
            //         job,
            //         data: job.data,
            //     });
            //     Sentry.captureException(e);
            // });

            if (!(window as any).SKIP_SYNC_STORAGE) {
                await db.sync.put({
                    ...job,
                    isCompleted: false,
                    inProgress: false,
                    isError: true,
                });
            }
        }

        if (!(window as any).SKIP_SYNC_STORAGE) {
            setTimeout(async () => {
                const completed = await db.sync.toArray();
                console.log(completed);
                completed.forEach((task) => {
                    if (task.isCompleted) {
                        console.log('delete');
                        db.sync.delete(task.id);
                    }
                });

                const hasTasks = (await db.tasks.count()) > 0;
                const hasEvents = (await db.calendarEvents.count()) > 0;

                if (hasTasks || hasEvents) {
                    return false;
                }

                const toDelete = [];
                const entries = await db.formEntries.each((entry) => {
                    // We can't delete here because .each() is a reader transaction
                    // Performing deletes here breaks indexeddb.
                    toDelete.push(entry.id);
                });

                for await (const id of toDelete) {
                    await db.formEntries.delete(id);
                }
            }, 5000);
        }

        if (window.ONE_BY_ONE) {
            break;
        }
    }
};
