import { FORM_GROUPS_SET } from "@rpforms/shared/build";
import config, { axiosClient } from "../config";
import { STORAGE_FORM_GROUPS } from "../constants";
import db from "../db";
import { ADD_SYNC_JOB, LOADING_TASKS, STOP_LOADING_TASKS } from "./types";

const startLoading = () => {
    return {
        type: LOADING_TASKS,
        payload: {},
    };
};

const stopLoading = () => {
    return {
        type: STOP_LOADING_TASKS,
        payload: {},
    };
};

const setGroups = (payload) => {
    return {
        type: FORM_GROUPS_SET,
        payload,
    };
};

export const fetchFormGroupsRemotely = () => async (dispatch) => {
    // add remote tasks
    const response = await axiosClient.get(`/form_groups.json`);
    const groups = [...response.data];

    dispatch(setGroups([...groups]));
    await db.formGroups.bulkPut(groups);
    dispatch(stopLoading());
};
