import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonModal,
    IonProgressBar,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { ErrorBoundary } from "@rpforms/shared/build";
import { sort } from "@rpforms/shared/build/utils";
import { useLiveQuery } from "dexie-react-hooks";
import { add, checkmarkCircleOutline } from "ionicons/icons";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { submitDraftForm } from "../actions/forms";
import { verifyStorage } from "../actions/storage";
import { createTask, removeTask } from "../actions/tasks";
import { CenteredLoader } from "../components/CenteredLoader";
import { TaskItem, TaskStatus } from "../components/TaskItem";
import db from "../db";
import { useVisibleTabs } from "../hooks/useVisibleTabs";

const EntityDetails = React.memo(({ match, history }: any) => {
    useVisibleTabs();
    const { entity_id } = match.params;
    const [showPopover, setShowPopover] = useState(false);
    const [loading, showLoading] = useState(false);
    const [reload, setReload] = useState(0);
    const dispatch = useDispatch();

    const formStore = useLiveQuery(() => db.forms.toArray());
    const taskStore = useLiveQuery(() => db.tasks.toArray());
    const entityStore = useLiveQuery(() => db.entities.toArray());
    const groupStore = useLiveQuery(() => db.formGroups.toArray());
    const entity = useLiveQuery(() => db.entities.get(parseInt(entity_id, 10)));
    const tasks = useLiveQuery(
        () =>
            db.tasks
                .where("entity_id")
                .equals(parseInt(entity_id, 10))
                .and((task) => task.status !== TaskStatus.SUBMITTED)
                .toArray(),
        [showPopover, reload]
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            if (typeof entity === "undefined") {
                return history.push(`/`);
            }
        }, 800);

        return () => {
            clearInterval(timer);
        };
    }, [entity]);

    const deleteOfflineForm = async (task) => {
        await removeTask({ ...task, entity_id });
        setTimeout(() => setReload(reload + 1), 650);
    };

    const duplicateTask = async (task) => {
        try {
            await verifyStorage(dispatch);
            await createTask(
                {
                    entity: { id: task.entity_id },
                    form: { id: task.form_id },
                    task,
                },
                true
            );
            setReload((r) => r + 1);
        } catch (e) {
            //
        }
    };

    const tasksListing = useMemo(() => {
        if (!entity || !tasks) {
            return [];
        }

        return sort(
            tasks.filter((task) => !task.was_completed),
            "name"
        )
            .sort((taskA, taskB) => {
                if (taskA.isDuplicate && !taskB.isDuplicate) {
                    return 1;
                }

                if (taskB.isDuplicate && !taskA.isDuplicate) {
                    return -1;
                }

                return 0; // keep sorting
            })
            .map((task: any) => {
                return (
                    <TaskItem
                        history={history}
                        task={task}
                        entity={entity}
                        duplicateTask={duplicateTask.bind(this, task)}
                        deleteTask={deleteOfflineForm.bind(this, task)}
                        key={task.id}
                    />
                );
            });
    }, [entity, tasks]);

    if (!entity) {
        return <CenteredLoader />;
    }

    const addForm = async (form) => {
        try {
            setShowPopover(false);
            await verifyStorage(dispatch);
            await createTask({ entity, form });
        } catch (e) {
            setShowPopover(false);
        } finally {
            setReload((r) => r + 1);
        }
    };

    const syncAll = async () => {
        showLoading(true);

        for await (const task of tasks) {
            try {
                await verifyStorage(dispatch);
                if (task.saveState && task.status !== TaskStatus.IN_QUEUE) {
                    await dispatch(
                        submitDraftForm({
                            task,
                        })
                    );
                }
            } catch (e) {
                if (e.message === "Disk almost full") {
                    showLoading(false);
                    break;
                }
            }
        }

        showLoading(false);
    };

    const isLoading = !formStore || !groupStore || !entityStore || !taskStore;

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/entities"} />
                    </IonButtons>
                    <IonTitle>{entity && entity.name} </IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={syncAll} color={"primary"}>
                            <IonIcon icon={checkmarkCircleOutline} /> Alle abschließen
                        </IonButton>

                        <IonButton onClick={(e) => setShowPopover(true)} color={"primary"}>
                            <IonIcon icon={add} /> Formular
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                {isLoading && <IonProgressBar color="secondary" type="indeterminate" />}
            </IonHeader>
            <IonContent>
                <ErrorBoundary>
                    <IonLoading isOpen={loading} message={"Formulare werden eingereiht..."} />
                    {!isLoading && (
                        <IonModal
                            cssClass={"form-add-popover"}
                            isOpen={showPopover}
                            onDidDismiss={(e) => setShowPopover(false)}
                        >
                            <IonHeader>
                                <IonToolbar style={{ padding: "0 15px" }}>Formulare</IonToolbar>
                            </IonHeader>
                            <div style={{ maxHeight: "500px", overflow: "auto" }}>
                                {groupStore
                                    .sort((groupA, groupB) => {
                                        if (groupA.name < groupB.name) {
                                            return -1;
                                        }
                                        if (groupA.name > groupB.name) {
                                            return 1;
                                        }
                                        return 0;
                                    })
                                    .map((group) => {
                                        return (
                                            <IonList key={group.id}>
                                                <IonListHeader>
                                                    <IonLabel>{group.name}</IonLabel>
                                                </IonListHeader>
                                                {formStore
                                                    .filter(
                                                        (f) =>
                                                            f.form_group &&
                                                            f.form_group.id === group.id
                                                    )
                                                    .sort((formA, formB) => {
                                                        if (formA.name < formB.name) {
                                                            return -1;
                                                        }
                                                        if (formA.name > formB.name) {
                                                            return 1;
                                                        }
                                                        return 0;
                                                    })
                                                    .map((form) => {
                                                        return (
                                                            <IonItem
                                                                key={form.id}
                                                                onClick={() => addForm(form)}
                                                            >
                                                                <IonLabel
                                                                    style={{ marginLeft: "30px" }}
                                                                >
                                                                    {form.name}
                                                                </IonLabel>
                                                            </IonItem>
                                                        );
                                                    })}
                                            </IonList>
                                        );
                                    })}
                                <IonList>
                                    <IonListHeader>
                                        <IonLabel>{"Ungruppiert"}</IonLabel>
                                    </IonListHeader>
                                    {formStore
                                        .filter((f) => !f.form_group)
                                        .map((form) => {
                                            return (
                                                <IonItem
                                                    key={form.id}
                                                    onClick={() => addForm(form)}
                                                >
                                                    <IonLabel style={{ marginLeft: "30px" }}>
                                                        {form.name}
                                                    </IonLabel>
                                                </IonItem>
                                            );
                                        })}
                                </IonList>
                            </div>
                        </IonModal>
                    )}
                    <div style={{ padding: "15px" }}>
                        <Row>
                            <Col>ObjektNr: {entity.entity_nr || "-"}</Col>
                            {entity.address && (
                                <Col>
                                    Addresse: {entity.address.street}, {entity.address.postalcode}{" "}
                                    {entity.address.city}
                                </Col>
                            )}
                            {entity.customer && (
                                <Col>
                                    Kunde: {entity.customer.customer_nr}, {entity.customer.name}{" "}
                                </Col>
                            )}
                        </Row>
                    </div>
                    <IonList>{tasksListing}</IonList>
                    <br />
                    <br />
                </ErrorBoundary>
            </IonContent>
        </>
    );
});

export default EntityDetails;
