import {
    IonIcon,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonNote,
    IonText,
} from "@ionic/react";
import { calendar, document, save, trash } from "ionicons/icons";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export enum TaskStatus {
    LOADING = 0,
    NEW = 1,
    DRAFT = 2,
    IN_QUEUE = 3,
    SUBMITTED = 4,
}

const IonTaskItem = styled<any>(IonItem)`
  transition: opacity ease-in;

  h2 {
    color: #000;
    font-size: 18px;
    font-weight: bold;
  }

  p {
    color: #666;
  }

  ${(props) =>
      props.status === TaskStatus.DRAFT &&
      css`
          --background: rgba(255, 230, 104, 0.32);
      `}

  ${(props) =>
      props.status === TaskStatus.LOADING &&
      css`
          opacity: 0.6;
      `}

  ${(props) =>
      props.status === TaskStatus.SUBMITTED &&
      css`
          --background: rgba(105, 187, 123, 0.3);
      `}

  ${(props) =>
      props.status === TaskStatus.IN_QUEUE &&
      css`
          --background: rgba(33, 33, 33, 0.2);
          opacity: 0.6;
      `}
`;

const TaskLabel = styled(IonLabel)`
    display: flex !important;
    align-items: center;
`;

const taskIcons = {
    [TaskStatus.NEW]: {
        icon: document,
        style: { paddingRight: "20px" },
        size: "large",
    },
    [TaskStatus.DRAFT]: {
        icon: document,
        style: { paddingRight: "20px" },
        size: "large",
    },
    [TaskStatus.IN_QUEUE]: {
        icon: document,
        style: { paddingRight: "20px" },
        size: "large",
    },
    [TaskStatus.SUBMITTED]: {
        icon: document,
        style: { paddingRight: "20px" },
        size: "large",
    },
};

export const TaskItem = ({
    task,
    entity,
    history,
    deleteTask = () => null,
    duplicateTask = () => null,
}: any) => {
    const startFormatted = moment(task.start).format("DD.MM.YYYY");
    const endFormatted = moment(task.end).format("DD.MM.YYYY");

    let subtitle = (
        <>
            {startFormatted} - {endFormatted}
        </>
    );

    switch (task.status) {
        case TaskStatus.SUBMITTED:
            subtitle = <>Protokoll übertragen</>;
            break;

        case TaskStatus.IN_QUEUE:
            subtitle = <>Protokoll bereit zum übertragen</>;
            break;

        case TaskStatus.DRAFT:
            subtitle = <>Bearbeitet</>;
            break;
    }

    const taskItem = (
        <IonTaskItem status={task.status} key={task.id}>
            <TaskLabel class="ion-text-wrap">
                <IonIcon {...taskIcons[task.status]} />

                <IonText color="primary">
                    <h2 style={{ alignContent: "center" }}>{task.name}</h2>
                    <p>{subtitle}</p>
                </IonText>
            </TaskLabel>
            <IonNote>
                {task.isLocal && "Nur auf diesem Gerät"}
                {task.isDuplicate && " / Kopie"}
                {task.calendar_event_id
                    ? task.isLocal
                        ? " / Gehört zu einem Kalendarevent"
                        : "Gehört zu einem Kalendarevent"
                    : ""}
            </IonNote>
        </IonTaskItem>
    );

    const editable =
        (task.status !== TaskStatus.IN_QUEUE && task.status !== TaskStatus.SUBMITTED) ||
        window.OPEN_SUBMITTED;

    const calendarEventParam = task.calendar_event_id ? `?event=${task.calendar_event_id}` : "";

    return (
        <IonItemSliding key={task.id}>
            {editable ? (
                <Link to={`/entities/entity/${entity.id}/${task.id}${calendarEventParam}`}>
                    {taskItem}
                </Link>
            ) : (
                taskItem
            )}
            <IonItemOptions>
                <IonItemOption onClick={duplicateTask} color="primary">
                    <IonIcon icon={save} />
                    Duplizieren
                </IonItemOption>
                {task.isLocal && (
                    <IonItemOption onClick={deleteTask} color="danger">
                        <IonIcon icon={trash} />
                        Löschen
                    </IonItemOption>
                )}
                {task.calendar_event_id && (
                    <>
                        <IonItemOption
                            onClick={() =>
                                history.push(`/calendarevents/event/${task.calendar_event_id}`)
                            }
                            color="secondary"
                            style={{ height: "100%" }}
                        >
                            <IonIcon icon={calendar} />
                            Zum Event
                        </IonItemOption>
                    </>
                )}
            </IonItemOptions>
        </IonItemSliding>
    );
};
