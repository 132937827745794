import { WARN_STORAGE_IN_MB } from "../config";
import { calculateInnoDBSize } from "../pages/Storage";

export const verifyStorage = async (dispatch) => {
    const storage = await calculateInnoDBSize();

    if (
        !(window as any).IGNORE_SETTINGS_WARNING &&
        storage.usage > WARN_STORAGE_IN_MB * 1024 * 1024
    ) {
        dispatch({ type: "SHOW_STORAGE_DIALOG" });
        throw new Error("Disk almost full");
    }
};
