import {
    IonIcon,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonNote,
    IonText,
} from "@ionic/react";
import { document, save, trash } from "ionicons/icons";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

enum TaskStatus {
    LOADING = 0,
    NEW = 1,
    DRAFT = 2,
    IN_QUEUE = 3,
    SUBMITTED = 4,
}

const IonTaskItem = styled<any>(IonItem)`
    transition: opacity ease-in;
    margin-left: 20px;
    h2 { color: #000; font-size: 18px;font-weight: bold; }
    p {
    color: #666;
    }
    ${(props) =>
        props.status === TaskStatus.DRAFT &&
        css`
            --background: rgba(255, 230, 104, 0.32);
        `}

    ${(props) =>
        props.status === TaskStatus.LOADING &&
        css`
            opacity: 0.6;
        `}

     ${(props) =>
         props.status === TaskStatus.SUBMITTED &&
         css`
             --background: rgba(105, 187, 123, 0.3);
         `}

       ${(props) =>
           props.status === TaskStatus.IN_QUEUE &&
           css`
               --background: rgba(33, 33, 33, 0.2);
               opacity: 0.6;
           `}
`;

const TaskLabel = styled(IonLabel)`
    display: flex !important;
    align-items: center;
`;

const taskIcons = {
    [TaskStatus.NEW]: {
        icon: document,
        style: { paddingRight: "20px" },
        size: "large",
    },
    [TaskStatus.DRAFT]: {
        icon: document,
        style: { paddingRight: "20px" },
        size: "large",
    },
    [TaskStatus.IN_QUEUE]: {
        icon: document,
        style: { paddingRight: "20px" },
        size: "large",
    },
    [TaskStatus.SUBMITTED]: {
        icon: document,
        style: { paddingRight: "20px" },
        size: "large",
    },
};

export const TaskListItem = React.memo(
    ({ task, entity, event }: any) => {
        let subtitle = <></>;
        switch (task.status) {
            case TaskStatus.SUBMITTED:
                subtitle = <>Protokoll übertragen</>;
                break;

            case TaskStatus.IN_QUEUE:
                subtitle = <>Protokoll bereit zum übertragen</>;
                break;

            case TaskStatus.DRAFT:
                subtitle = <>Bearbeitet</>;
                break;
        }
        const taskItem = (
            <IonTaskItem status={task.status} key={task.id}>
                <TaskLabel class="ion-text-wrap">
                    <IonIcon {...taskIcons[task.status]} />

                    <IonText color="primary">
                        <h2 style={{ alignContent: "center" }}>{task.name}</h2>
                        <p>{subtitle}</p>
                    </IonText>
                </TaskLabel>
                <IonNote>
                    {task.form_entry_id && "Dieses Formular ist bereits vorausgefüllt"}
                </IonNote>
            </IonTaskItem>
        );

        const editable =
            (task.status !== TaskStatus.IN_QUEUE && task.status !== TaskStatus.SUBMITTED) ||
            window.OPEN_SUBMITTED;

        return (
            <IonItemSliding key={task.id}>
                {editable && (
                    <Link to={`/entities/entity/${entity.id}/${task.id}?event=${event?.id}`}>
                        {taskItem}
                    </Link>
                )}
                {!editable && <>{taskItem}</>}
            </IonItemSliding>
        );
    }
);
