import { Capacitor } from "@capacitor/core";
import Dexie from "dexie";

const db: any = new Dexie(Capacitor.platform === "web" ? "rpforms-web" : "rpforms");

const dbVersion: any = db.version(20).stores({
    tasks: "++id,name,calendar_event_id,entity_id,status",
    calendarEvents: "++id,name,entity_id,was_completed",
    calendarEventTypes: "++id",
    forms: "++id,name",
    entities: "++id,name",
    sync: "++id,isCompleted",
    formGroups: "++id",
    formEntries: "++id",
});

(window as any).db = db;
export default db;
