import axios from "axios";

// export const API_URL = "https://api.staging.rpforms.kega.network";
export const API_URL = process.env.REACT_APP_BACKEND_URL;
export const COMPANY_ID = 1;
export const WARN_STORAGE_IN_MB = 240;
export const MAX_STORAGE_IN_MB = 512;

// tslint:disable-next-line:no-var-requires
(window as any).STAMP_URL = require("./images/stamp.png"); // TODO: replace with global config
const config = {
    get CHECK_NETWORK_SPEED() {
        return localStorage.getItem("checkNetworkSpeed")
            ? localStorage.getItem("checkNetworkSpeed")
            : "0";
    },
    get ENDPOINT_URL() {
        return localStorage.getItem("endpoint") ? localStorage.getItem("endpoint") : API_URL;
    },
    get DEVICE_ID() {
        return localStorage.getItem("deviceID")
            ? parseInt(String(localStorage.getItem("deviceID")), 10)
            : null;
    },
    get DEVICE_TOKEN() {
        return localStorage.getItem("deviceToken")
            ? localStorage.getItem("deviceToken")
            : "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo4LCJkZXZpY2VfaWQiOjMsImV4cCI6MTY4MzQ4MjAwNH0.yPzrISelf2MU4k1p1q_o2W2Ir4JkvE2xQIlbjMohzww";
    },
};

export default config;
const axiosClient = axios.create({
    timeout: 90 * 1000,
    baseURL: localStorage.getItem("endpoint"),
});
axiosClient.defaults.headers.common.Authorization = config.DEVICE_TOKEN;
export { axiosClient };
