import { TaskStatus } from "../components/TaskItem";
import config, { axiosClient } from "../config";
import db from "../db";
import { uniqueNumber } from "../utils";
import { SET_CALENDAR_EVENTS } from "./types";

export const setCalendarEvents = (payload) => {
    return {
        type: SET_CALENDAR_EVENTS,
        payload,
    };
};

export const fetchCalendarEventsRemotely = () => async (dispatch) => {
    const response = await axiosClient.get(
        `${config.ENDPOINT_URL}/devices/${config.DEVICE_ID}/calendar_events.json`,
        {
            headers: { authorization: config.DEVICE_TOKEN },
        }
    );

    const events = [...response.data];

    // remove events not being present in the remote tasks set anymore
    const dbEvents = await db.calendarEvents.toArray();
    for await (const remoteEvent of dbEvents) {
        if (!events.find((event) => event.id === remoteEvent.id) && !remoteEvent.saveState) {
            await db.calendarEvents.delete(remoteEvent.id);
        }
    }

    const preparedEvents = [];

    for await (const ev of events) {
        const existingEvent = await db.calendarEvents.get(ev.id);
        preparedEvents.push({
            ...ev,
            saveState: existingEvent ? existingEvent.saveState : null,
        });
    }

    await db.calendarEvents.bulkPut(preparedEvents);
    await createTasksFromEvents();
};

export const createTasksFromEvents = async () => {
    const events = await db.calendarEvents.toArray();
    const tasks = events.map((event) => event.device_tasks).flat();

    for await (const task of tasks) {
        if (!task.form_entry_id) {
            continue;
        }
        const taskResponse = await axiosClient.get(
            `${config.ENDPOINT_URL}/form_entries/${task.form_entry_id}.json?task_id=${task.id}`
        );
        await db.formEntries.put(taskResponse.data);
    }

    const dbTasks = await db.tasks.filter((e) => e.isLocal === false).toArray();
    for await (const remoteTask of dbTasks) {
        if (!tasks.find((task) => task.id === remoteTask.id) && !remoteTask.saveState) {
            await db.tasks.delete(remoteTask.id);
        }
    }

    const preparedTasks = [];

    for await (const task of tasks) {
        const existingTask = await db.tasks.get(task.id);
        preparedTasks.push({
            ...task,
            name: task.form_entry_id ? task.name.split(" - ")[0] : task.name.split(" - ")[1],
            isDuplicate: false,
            status: TaskStatus.NEW,
            task_id: task.id,
            isLocal: false,
            saveState: existingTask ? existingTask.saveState : null,
        });
    }

    await db.tasks.bulkPut(preparedTasks);

    const remoteTasks = await db.tasks.filter((e) => e.isLocal === false).toArray();
    for await (const remoteTask of remoteTasks) {
        if (remoteTask.saveState) {
            continue;
        }
        // Remote Task is not present anymore.
        if (!tasks.find((task) => task.id === remoteTask.id)) {
            await db.tasks.delete(remoteTask.id);
            continue;
        }

        if (remoteTask.end && remoteTask.start && remoteTask.end <= +new Date()) {
            await db.tasks.delete(remoteTask.id);
        }
    }
};
