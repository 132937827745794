import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import * as React from "react";

export const CenteredLoader = () => {
    return (
        <IonContent>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    minHeight: "500px",
                }}
                className="ion-align-items-center ion-justify-content-center ion-padding"
            >
                <h2>Wird geladen...</h2>
                <div>
                    <IonSpinner></IonSpinner>
                </div>
            </div>
        </IonContent>
    );
};
