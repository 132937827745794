import {
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonProgressBar,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { useLiveQuery } from "dexie-react-hooks";
import { calendar, document, filing, home, refresh } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { upload } from "../actions/sync";
import db from "../db";
import { calculateInnoDBSize } from "../pages/Storage";
import { TaskStatus } from "./TaskItem";

const quotaWarn = 320;

export const StorageDialog = () => {
    const storage = useSelector<any, any>((state) => state.storage);

    const [quotaUsed, setQuotaUsed] = useState(0);
    const [quotaMax, setQuotaMax] = useState(0);
    const [quotaTable, setQuotaTable] = useState<any>({});
    const [ignore, setIgnore] = useState(false);
    const [refreshing, setRefresh] = useState(0);
    const [loading, setLoading] = useState(false);
    const syncUpdate = useSelector<any>((state) => state.sync.update);
    const jobs = useLiveQuery(() => db.sync.toArray(), [syncUpdate]);

    const dispatch = useDispatch();

    useEffect(() => {
        const checkStorage = async () => {
            const quota = await calculateInnoDBSize();
            setQuotaMax(quota.quota / 1024 / 1024);
            setQuotaUsed(quota.usage / 1024 / 1024);
            setQuotaTable(quota.perTable);
            return checkStorage;
        };

        const cleanStorage = async () => {
            /**
             * Remove successfully transferred tasks
             */

            const tasksToDelete = [];
            await db.tasks.each((task) => {
                if (task.status === TaskStatus.SUBMITTED) {
                    tasksToDelete.push(task.id);
                }
            });

            for await (const id of tasksToDelete) {
                await db.tasks.delete(id);
            }

            /**
             * Routine to remove orphaned form entries.
             * These form entries are created whenever a task is assigned to the device
             * for an existing form entry. I.e. form entries from 2021 get reassigned 2022.
             *
             * When there is no task and no events, we remove all orphaned form entries since
             * they can't exist without a task/event naturally.
             */
            const hasTasks = (await db.tasks.count()) > 0;
            const hasEvents = (await db.calendarEvents.count()) > 0;

            if (hasTasks || hasEvents) {
                return false;
            }

            const toDelete = [];
            const entries = await db.formEntries.each((entry) => {
                // We can't delete here because .each() is a reader transaction
                // Performing deletes here breaks indexeddb.
                toDelete.push(entry.id);
            });

            for await (const id of toDelete) {
                await db.formEntries.delete(id);
            }
        };

        cleanStorage();
        checkStorage();

        const interval = setInterval(() => {
            checkStorage();
            cleanStorage();
        }, 90 * 1000);

        return () => clearInterval(interval);
    }, [refreshing]);

    const optimizeStorage = async () => {
        setLoading(true);
        try {
            await dispatch(upload(jobs));
        } catch (e) {
            alert(
                "Speicherplatz konnte nicht optimiert werden, bitte übertragen Sie Protokolle manuell."
            );
            setLoading(false);
        } finally {
            setRefresh((r) => r + 1);
            setLoading(false);
        }
    };

    const close = () => {
        dispatch({ type: "HIDE_STORAGE_DIALOG" });
    };

    const pendingJobs = jobs ? jobs.filter((job) => job.isCompleted !== true).length : 0;

    return (
        <IonModal isOpen={storage.showStorageDialog}>
            <IonToolbar>
                <IonButtons slot="start"></IonButtons>
                <IonTitle>Jetzt Speicherplatz reduzieren</IonTitle>
            </IonToolbar>
            {loading && <IonProgressBar color="success" type="indeterminate" />}

            <IonContent>
                {loading && (
                    <div style={{ padding: "20px" }}>
                        Achtung, bitte Speicher reduzieren!
                        <br />
                        <strong>{pendingJobs} Protokolle</strong> in der Warteschlange.
                    </div>
                )}
                {!loading && (
                    <p style={{ padding: "20px" }}>
                        Der Speicherplatz auf diesem Gerät ist nahezu voll. Aktuell in Verwendung
                        sind <strong>{quotaUsed.toFixed(2)} MB</strong>. Das kann zur Folge haben,
                        dass das Gerät sich aufhängt und nicht mehr rund läuft. Bitte übertrage
                        JETZT abgeschlossene Protokolle, um wieder Speicher freizugeben.
                        <br />
                        <p style={{ color: "red" }}>
                            Wenn dies nicht möglich sein sollte weil die Warnung erneut auftritt,
                            kontaktiere bitte Marc.
                        </p>
                        <strong>{pendingJobs} Protokolle</strong> können übertragen werden.
                        <IonList>
                            <IonItem>
                                <IonIcon slot="start" icon={document} />
                                <IonLabel>Protokolle</IonLabel>
                                <IonBadge
                                    slot="end"
                                    color={
                                        (quotaTable.formEntries ?? 0) / 1024 / 1024 > quotaWarn
                                            ? "danger"
                                            : "default"
                                    }
                                >
                                    ca. {((quotaTable.formEntries ?? 0) / 1024 / 1024).toFixed(2)}{" "}
                                    MB
                                </IonBadge>
                            </IonItem>
                            <IonItem>
                                <IonIcon slot="start" icon={refresh} />
                                <IonLabel>Sync</IonLabel>
                                <IonBadge
                                    slot="end"
                                    color={
                                        (quotaTable.sync ?? 0) / 1024 / 1024 > quotaWarn
                                            ? "danger"
                                            : "default"
                                    }
                                >
                                    ca. {((quotaTable.sync ?? 0) / 1024 / 1024).toFixed(2)} MB
                                </IonBadge>
                            </IonItem>
                            <IonItem>
                                <IonIcon slot="start" icon={home} />
                                <IonLabel>Objekte</IonLabel>
                                <IonBadge
                                    slot="end"
                                    color={
                                        (quotaTable.entities ?? 0) / 1024 / 1024 > quotaWarn
                                            ? "danger"
                                            : "default"
                                    }
                                >
                                    ca. {((quotaTable.entities ?? 0) / 1024 / 1024).toFixed(2)} MB
                                </IonBadge>
                            </IonItem>
                            <IonItem>
                                <IonIcon slot="start" icon={filing} />
                                <IonLabel>Aufgaben</IonLabel>
                                <IonBadge
                                    slot="end"
                                    color={
                                        (quotaTable.tasks ?? 0) / 1024 / 1024 > quotaWarn
                                            ? "danger"
                                            : "default"
                                    }
                                >
                                    ca. {((quotaTable.tasks ?? 0) / 1024 / 1024).toFixed(2)} MB
                                </IonBadge>
                            </IonItem>
                            <IonItem>
                                <IonIcon slot="start" icon={calendar} />
                                <IonLabel>Kalendar</IonLabel>
                                <IonBadge
                                    slot="end"
                                    color={
                                        (quotaTable.calendarEvents ?? 0) / 1024 / 1024 > quotaWarn
                                            ? "danger"
                                            : "default"
                                    }
                                >
                                    ca.{" "}
                                    {((quotaTable.calendarEvents ?? 0) / 1024 / 1024).toFixed(2)} MB
                                </IonBadge>
                            </IonItem>
                        </IonList>
                    </p>
                )}
            </IonContent>
            <IonFooter
                style={{
                    "padding": "10px",
                    "display": "flex",
                    "justify-content": "space-between",
                }}
            >
                <IonButton color={"success"} onClick={() => optimizeStorage()}>
                    Protokolle jetzt übertragen
                </IonButton>
                <IonButton onClick={() => close()} color={"warning"}>
                    Schließen
                </IonButton>
            </IonFooter>
        </IonModal>
    );
};
