import { SET_ERROR, UNSET_ERROR } from "../actions/types";

const initialState = {
    message: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_ERROR:
            return { ...state, message: action.payload };

        case UNSET_ERROR:
            return { ...state, message: false };
    }

    return state;
}
