import {
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { disc as archive, gitNetwork, person, phonePortrait, rocket } from "ionicons/icons";
import React, { useEffect, useState } from "react";

import { ErrorBoundary } from "@rpforms/shared/build";
import { Link } from "react-router-dom";
import config from "../config";

const Settings: React.FunctionComponent = () => {
    const deviceID = config.DEVICE_ID;
    const [endpoint, setEndpoint] = useState(config.ENDPOINT_URL);
    const [deviceToken, setDeviceToken] = useState(config.DEVICE_TOKEN);
    const writeButtonChangesToLocalStorage = () => {
        localStorage.setItem("endpoint", endpoint);
        localStorage.setItem("deviceToken", deviceToken);
    };

    const onProductionButtonClick = () => {
        setEndpoint("https://api.rpforms.kega.network");
        setDeviceToken(
            "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo4LCJkZXZpY2VfaWQiOjMsImV4cCI6MTc4NzY1OTM3NH0.FB5ocwKBlmnaI5acl2OOZg5M2725lD7qcRLN5pCXyg8"
        );
    };

    const onStagingButtonClick = () => {
        setEndpoint("https://api.staging.rpforms.kega.network");
        setDeviceToken(
            "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMCwiZGV2aWNlX2lkIjoxLCJleHAiOjE3ODc2NTk0OTl9.AxQjaH6RBMOTmvRLFLR4nY_-iX95rUFtFebu6_VnyNQ"
        );
    };

    const onLocalButtonClick = () => {
        setEndpoint("http://localhost:3008");
        setDeviceToken(
            "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMCwiZGV2aWNlX2lkIjoxLCJleHAiOjE3ODc2NTk0OTl9.AxQjaH6RBMOTmvRLFLR4nY_-iX95rUFtFebu6_VnyNQ"
        );
    };

    useEffect(() => {
        writeButtonChangesToLocalStorage();
    }, [endpoint, deviceToken]);

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Einstellungen</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <ErrorBoundary>
                    <IonList>
                        <IonItem>
                            <IonIcon slot="start" icon={phonePortrait} />
                            <IonLabel position={"floating"}>Geräte ID / iPad Nummer</IonLabel>
                            <IonInput
                                value={deviceID ? `${deviceID}` : ""}
                                onIonInput={(e: any) =>
                                    localStorage.setItem("deviceID", (e.detail as any).target.value)
                                }
                            />
                        </IonItem>
                        <IonItem>
                            <IonIcon icon={rocket} slot="start" />
                            <IonLabel position={"stacked"}>Fast switch settings</IonLabel>
                            <div style={{ display: "flex" }}>
                                <IonButton
                                    size="small"
                                    onClick={(e: any) => onProductionButtonClick()}
                                >
                                    Production
                                </IonButton>
                                <IonButton
                                    color="danger"
                                    size="small"
                                    onClick={(e: any) => onStagingButtonClick()}
                                >
                                    Staging
                                </IonButton>
                                <IonButton
                                    color="secondary"
                                    size="small"
                                    onClick={(e: any) => onLocalButtonClick()}
                                >
                                    localhost:3008
                                </IonButton>
                            </div>
                        </IonItem>
                        <IonItem>
                            <IonIcon icon={gitNetwork} slot="start" />
                            <IonLabel position={"floating"}>Zugriffspunkt</IonLabel>
                            <IonInput
                                value={endpoint ? `${endpoint}` : ""}
                                onIonInput={(e: any) =>
                                    localStorage.setItem("endpoint", (e.detail as any).target.value)
                                }
                            />
                        </IonItem>
                        <IonItem>
                            <IonIcon icon={person} slot="start" />
                            <IonLabel position={"floating"}>Anmeldetoken</IonLabel>
                            <IonInput
                                value={deviceToken ? `${deviceToken}` : ""}
                                onIonInput={(e: any) =>
                                    localStorage.setItem(
                                        "deviceToken",
                                        (e.detail as any).target.value
                                    )
                                }
                            />
                        </IonItem>
                        <IonItem>
                            <IonIcon icon={person} slot="start" />
                            <IonLabel position={"floating"}>Version</IonLabel>
                            <IonInput
                                readonly
                                value={process.env.REACT_APP_COMMIT || "local"}
                                onIonInput={(e: any) => null}
                            />
                        </IonItem>
                        <Link to={`/settings/storage`}>
                            <IonItem>
                                <IonIcon icon={archive} slot="start" />
                                <IonLabel>Speicher</IonLabel>
                            </IonItem>
                        </Link>
                    </IonList>
                </ErrorBoundary>
            </IonContent>
        </>
    );
};

export default Settings;
